<template>
  <v-layout max-width class="flex-column">
    <div class="flex-y-center flex-justify-between px-4 py-3 white mb-3" style="">
      <div class=" flex-y-center">
        <!-- search.data.fieldCategory -->
        <v-btn dark small color="#00bea9" depressed min-width="32" width="80" class=" mr-2" height="30" style=" padding:0 8px;" @click="drawer.fieldTypeShow=false,drawer.show=true,drawer.data={fieldCategory:search.data.fieldCategory}">
          <v-icon size="16">mdi-plus</v-icon>新增属性
        </v-btn>
        <v-btn
          dark
          small
          color="primary"
          depressed
          min-width="32"
          class=" mr-2"
          height="30"
          style=" padding:0 8px;"
          @click="dialog.list = [],dialog.search.pageNo=1,dialog.selected=[],
                  dialog.selectedAll=false,dialog.search.data={},dialog.show=true,getQueryProjectList()"
        >
          前台属性同步
        </v-btn>
        <v-btn dark small color="primary" depressed min-width="32" width="80" class=" mr-2" height="30" style=" padding:0 8px;" @click="$refs.listSort && $refs.listSort.show()">
          <v-icon size="16">mdi-sort</v-icon>排序
        </v-btn>
      </div>
      <div class=" flex-y-center">
        <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:110px;">属性分类：</span>
        <v-menu
          bottom
          max-height="300"
          offset-overflow
          transition="slide-y-transition"
          offset-y
          min-width="190"
        >
          <template v-slot:activator="{ on }">
            <div class=" flex-1 default-input flex-y-center" color="primary" style="height:30px;" v-on="on">
              <!-- search.data.fieldCategory?fieldCategory[search.data.fieldCategory].name:'' -->
              <input readonly placeholder="属性分类" class=" max-width" type="text" :value="(search.data.fieldCategory&&categoryList.filter(_t => search.data.fieldCategory===_t.id).length)?(categoryList.filter(_t => search.data.fieldCategory===_t.id)[0].name):''">
            </div>
          </template>
          <v-list class="pa-0 radius-0" subheader>
            <!-- fieldCategoryArr -->
            <v-list-item v-for="(item,index) in categoryList" :key="index" @click="search.data.fieldCategory=item.id,$forceUpdate(),initList()">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-btn dark small  color="grey lighten-1" :loading="listLoading"  class=" mx-1" @click="search.data.fieldCategory='',initList()" title="重置筛选" depressed min-width='34' style="height:34px; padding:0;">
                    <v-icon size="18">mdi-restore</v-icon>
                  </v-btn> -->
        <!-- <div class="flex-y-center mr-2" style="width:120px;">
          <form-item class="mr-0" placeholder="项目名称" type="text"
                     v-model="search.data.project_name"></form-item>
        </div>
        <v-btn dark small color="primary" depressed min-width='34' style="height:34px; padding:0;">
          <v-icon size="18">mdi-magnify</v-icon>
        </v-btn> -->
        <!-- <v-btn depressed color="primary">搜索</v-btn> -->
      </div>

    </div>
    <div class=" white">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="search.pageSize"
        class="pb-5 pa-4"
        hide-default-footer
        item-key="id"
        no-data-text="暂无查询数据"
        :loading="listLoading"
      >
        <template v-slot:item="{item,index}">
          <tr>
            <!-- <td></td> -->
            <td>{{ (index+1)+(search.pageSize*(search.pageNo-1)) }}</td>
            <!-- <td>{{item.fieldCategory?fieldCategory[item.fieldCategory].name:''}}</td> -->
            <td>{{ (item.fieldCategory&&categoryList.filter(_t => item.fieldCategory===_t.id).length)?(categoryList.filter(_t => item.fieldCategory===_t.id)[0].name):'' }}</td>
            <td>{{ item.fieldName }}</td>
            <!-- fieldTypeList -->

            <!-- <td>{{item.fieldType?fieldType[item.fieldType].name:''}}</td> -->
            <td>{{ fieldTypeList.find(_t => _t.id === item.fieldType)&&fieldTypeList.find(_t => _t.id === item.fieldType).name }}</td>
            <td>{{ item.sort }}</td>
            <td>{{ item.isRequired==1?'是':'否' }}</td>
            <td>{{ item.isShow==1?'是':'否' }}</td>
            <td>{{ item.isQuery==1?'是':'否' }}</td>

            <td>{{ item.isVerify==1?'项目唯一性':(item.isVerify==2?'系统唯一性':(item.isVerify=='0'?'否':'-')) }}</td>
            <td>{{ item.defaultValue }}</td>
            <td>
              <span :class="item.statusFlag==1?'error--text':''">
                {{ item.statusFlag==1?'禁用':'正常' }}
              </span>
            </td>
            <td>
              <!-- <v-icon color="primary" size="18" @click="drawer.data={...item},drawer.show=true" class=" mr-2">mdi-square-edit-outline</v-icon> -->
              <v-icon v-if="item.statusFlag==1" disabled color="primary" size="18" title="编辑" class=" mr-2">mdi-square-edit-outline</v-icon>
              <v-icon v-else color="primary" size="18" title="编辑" class=" mr-2" @click="getCustomFieldQueryById(item.id)">mdi-square-edit-outline</v-icon>

              <v-icon
                v-if="item.statusFlag==1"
                color="primary"
                size="20"
                title="恢复"
                class=" mr-2"
                @click="activeList($api.customFieldSetFieldStatus,'post','恢复',{id:item.id,statusFlag:'0'})"
              >mdi-replay</v-icon>
              <v-icon
                v-else
                color="error"
                size="20"
                title="禁用"
                class=" mr-2"
                @click="activeList($api.customFieldSetFieldStatus,'post','禁用',{id:item.id,statusFlag:'1'})"
              >mdi-cancel</v-icon>

              <v-icon
                color="error"
                size="20"
                title="删除"
                @click="activeList($api.customFieldDelete,'delete','删除',{id:item.id})"
              >mdi-delete-outline</v-icon>
            </td>
          </tr>
        </template>
      <!-- <template v-slot:item.active="{item}">
          <v-icon color="primary" size="18" @click="dialog.data={...item},dialog.show=true">mdi-square-edit-outline</v-icon>
          <a>禁用</a>
      </template> -->
      </v-data-table>
      <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end ">
        <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getList" @changePageSize="changePageSize" />
      </div>
    </div>
    <!-- 前台属性同步 弹窗 -->
    <v-dialog
      v-model="dialog.show"
      width="1250"
      max-width="100%"
    >
      <v-card class=" flex-column flex-justify-between" style="height:740px;">
        <v-card-title class=" flex-0 flex-shrink-0 text-h5 lighten-2">
          前台属性同步
        </v-card-title>
        <v-divider />
        <v-card-text class=" flex-1 px-0 overflow-auto">
          <div class=" mb-3 max-width">

            <div class="flex-justify-between flex-y-center">
              <div class=" flex-y-center">
                <!-- <span class=" flex-0 flex-shrink-0 flex-justify-end ml-4 body-1">属性分类：</span>
                  <v-menu bottom
                        max-height="300"
                        offset-overflow
                        transition="slide-y-transition"
                        offset-y
                        >
                        <template v-slot:activator="{ on }">
                            <div class=" flex-1 default-input body-1" color="primary" style="height:36px;" v-on="on">
                                <input disabled placeholder="属性分类" class=" max-width" type="text" :value="dialog.search.fieldCategory?fieldCategory[dialog.search.fieldCategory].name:''" />
                            </div>
                        </template>
                        <v-list class="pa-0 radius-0" subheader>
                            <v-list-item :key="index" v-for="(item,index) in fieldCategory" @click="dialog.search.fieldCategory=item.id,$forceUpdate(),getQueryProjectList()">
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                  </v-menu> -->
              </div>
              <div class="flex-justify-end px-3 py-2">
                <!-- @input="searchDialogList" -->
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="dialog.search.data.syncFlag"
                    class=" flex-1 flex-shrink-0"
                    :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                    required
                    placeholder="是否已同步"
                    dense
                    type="select"
                    allow-close
                    @input="$forceUpdate()"
                    @select="(e)=>{dialog.search.data.syncFlag = e.val,dialog.search.pageNo = 1,getQueryProjectList()}"
                    @cleanVal="(e)=>{dialog.search.data.syncFlag = e.val,dialog.search.pageNo = 1,getQueryProjectList()}"
                  />
                </div>
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="dialog.search.data.projectNumber"
                    class="mr-0"
                    placeholder="项目编号"
                    type="text"
                    allow-close
                    @onEnter="dialog.search.pageNo = 1,getQueryProjectList()"
                  />
                </div>
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="dialog.search.data.projectName"
                    class="mr-0"
                    placeholder="项目名称"
                    type="text"
                    allow-close
                    @onEnter="dialog.search.pageNo = 1,getQueryProjectList()"
                  />
                </div>
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="dialog.search.data.projectManagerName"
                    class="mr-0"
                    placeholder="项目负责人"
                    type="text"
                    allow-close
                    @onEnter="dialog.search.pageNo = 1,getQueryProjectList()"
                  />
                </div>
                <v-btn
                  dark
                  small
                  color="primary"
                  depressed
                  min-width="34"
                  height="30"
                  style=" padding:0;"
                  @click="dialog.search.pageNo = 1,getQueryProjectList()"
                >
                  <v-icon size="18">mdi-magnify</v-icon>
                </v-btn>
                <v-btn dark small color="grey lighten-1" class=" mx-1" title="重置筛选" depressed min-width="34" height="30" style=" padding:0;" @click="dialog.search.data={},dialog.search.pageNo = 1,getQueryProjectList()">
                  <v-icon size="18">mdi-restore</v-icon>
                </v-btn>
              </div>
            </div>

            <div class=" flex-column flex-1  overflow-auto">
              <v-data-table
                :headers="dialog.headers"
                :items="dialog.list"
                :items-per-page="dialog.search.pageSize"
                class="pb-5 max-width header-grey"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
                :loading="dialog.listLoading"
                disable-sort
                dense
              >
                <template v-slot:header.checkbox>
                  <v-checkbox
                    v-model="dialog.selectedAll"
                    class="mt-0 py-2"
                    dense
                    label="全选"
                    hide-details
                    @change="(e)=>{selectedAll(e,'left')}"
                  />
                </template>
                <template v-slot:item.rowIndex="{index}">
                  {{ (index+1)+(dialog.search.pageSize*(dialog.search.pageNo-1)) }}
                </template>
                <template v-slot:item.checkbox="{item}">
                  <v-checkbox
                    v-if="item.syncFlag!=1"
                    v-model="dialog.selected"
                    class="mt-0 pt-0"
                    dense
                    hide-details
                    :value="item.id"
                    @change="selectedIt()"
                  />
                </template>
                <template v-slot:item.projectName="{item}">
                  <div class=" font-overflow" style="max-width:400px;" :title="item.projectName">
                    {{ item.projectName }}
                  </div>
                </template>
                <template v-slot:item.syncFlag="{item}">
                  {{ item.syncFlag==1?'是':(item.syncFlag==0?'否':'') }}
                </template>
              </v-data-table>
              <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
                <pagination
                  v-model="dialog.search.pageNo"
                  :size="dialog.search.pageSize"
                  :total="dialog.total"
                  @input="getQueryProjectList()"
                  @changePageSize="(e)=>{dialog.search.pageSize=e,dialog.search.pageNo=1,$forceUpdate(),getQueryProjectList()}"
                />
              </div>
            </div>

          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions class=" flex-0 flex-shrink-0">
          <v-spacer />
          <v-btn
            depressed
            width="100"
            height="30"
            @click="dialog.show = false,dialog.list=[]"
          >
            取消
          </v-btn>
          <v-btn
            color="primary"
            :loading="dialog.btnLoading"
            depressed
            width="100"
            height="30"
            @click="syncProjects('-1')"
          >
            全部同步
          </v-btn>
          <v-btn
            :disabled="!dialog.selected.length"
            color="primary"
            :loading="dialog.btnLoading"
            depressed
            width="100"
            height="30"
            @click="syncProjects"
          >
            批量同步
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- v-model="drawer.show"
      temporary
      app
      right
      width="700"

      clipped touchless stateless -->

    <v-navigation-drawer
      v-model="drawer.show"
      app
      floating
      right

      temporary
      width="800"
      style="transform: none;transition-duration: 0s"
    >
      <form v-if="drawer.show" class="max-height flex-column overflow-hidden" @submit.prevent="submit">

        <div
          class="flex-0 flex-justify-between flex-y-center py-3 px-5"
          style="border-bottom:1px solid #e1e1e1"
        >
          <span class="text-h6">{{ drawer.data.id?'编辑属性':'新增属性' }}</span>
          <v-icon @click="drawer.show=false,drawer.data={}">mdi-close</v-icon>
        </div>
        <div class="flex-1 flex-column overflow-auto mt-12 mx-12 px-12">
          <div v-if="drawer.loading" class="max-height max-width" style="position: absolute;top:0;left:0">
            <v-overlay
              absolute="absolute"
              :value="drawer.loading"
              opacity="0.1"
            >
              <v-progress-circular
                indeterminate
                size="64"
              />
            </v-overlay>
          </div>
          <div class="mb-5 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
              <span class="red--text ml-1">*</span>属性分类：
            </div>

            <!-- drawer.data.fieldCategory?fieldCategory[drawer.data.fieldCategory].name:'' -->
            <form-item
              v-if="drawer.data.id"
              class="mr-0"
              disabled
              placeholder="属性分类"
              type="text"
              :value="(drawer.data.fieldCategory&&categoryList.filter(_t => drawer.data.fieldCategory===_t.id).length)?(categoryList.filter(_t => drawer.data.fieldCategory===_t.id)[0].name):''"
            />
            <v-menu
              v-else
              bottom
              max-height="300"
              offset-overflow
              transition="slide-y-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <div class=" flex-1 flex-justify-between default-input flex-y-center" color="primary" style="height:34px;background:#fdfdfd;" v-on="on">
                  <input readonly placeholder="属性分类" class=" max-width" type="text" :value="(drawer.data.fieldCategory&&categoryList.filter(_t => drawer.data.fieldCategory===_t.id).length)?(categoryList.filter(_t => drawer.data.fieldCategory===_t.id)[0].name):''">
                  <v-icon class="" size="20">mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-list class="pa-0 radius-0" subheader>
                <!-- fieldCategoryArr -->
                <!-- [3, 4, 5, 7, 14].includes(drawer.data.fieldType) -->
                <v-list-item v-for="(item,index) in categoryList" :key="index" @click="changeFieldCategory(item.id)">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="mb-5 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
              <span class="red--text ml-1">*</span>属性名称：
            </div>
            <form-item
              v-model="drawer.data.fieldName"
              class="mr-0"
              placeholder="属性名称"
              required
              type="text"
            />
          </div>

          <div class=" flex-y-center mb-5">
            <span class=" flex-0 flex-shrink-0 flex-justify-end body-1" style="width:140px;"><span class="red--text ml-1">*</span>类型：</span>
            <!-- drawer.data.fieldType?fieldType[drawer.data.fieldType].name:'' -->
            <form-item
              v-if="drawer.data.id"
              class="mr-0"
              required
              placeholder="类型"
              disabled
              type="text"
              :value="(drawer.data.fieldType&&fieldTypeList.find(_t => _t.id === drawer.data.fieldType))?fieldTypeList.find(_t => _t.id === drawer.data.fieldType).name:''"
            />
            <v-menu
              v-else
              v-model="drawer.fieldTypeShow"
              bottom
              max-height="300"
              offset-overflow
              transition="slide-y-transition"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <div class=" flex-1 flex-justify-between default-input flex-y-center" color="primary" style="height:34px;background:#fdfdfd;position: relative;" v-on="on">
                  <input readonly required placeholder="属性类型" class=" max-width" type="text" :value="(drawer.data.fieldType&&fieldTypeList.find(_t => _t.id === drawer.data.fieldType))?fieldTypeList.find(_t => _t.id === drawer.data.fieldType).name:''">
                  <v-icon class="" size="20">mdi-chevron-down</v-icon>
                  <input required class=" max-width" style="position: absolute;opacity: 0; width:1px;" type="text" :value="(drawer.data.fieldType&&fieldTypeList.find(_t => _t.id === drawer.data.fieldType))?fieldTypeList.find(_t => _t.id === drawer.data.fieldType).name:''">
                </div>
              </template>
              <v-list class="pa-0 radius-0" subheader>
                <template v-for="(item,index) in fieldTypeList">
                  <v-list-item :key="index" :disabled="[17,18].includes(drawer.data.fieldCategory) && (item.id === 7)" @click="!([17,18].includes(drawer.data.fieldCategory) && (item.id === 7)) && changeType(item.id)">
                    <v-list-item-title :class="([17,18].includes(drawer.data.fieldCategory) && (item.id === 7))?'grey--text':''">{{ item.name }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </div>
          <!-- fieldOptions -->

          <!-- <div  v-if="[1,2,6].includes(drawer.data.fieldType)" class="mb-7 flex-align-start"> -->
          <template v-if="drawer.data.fieldType">
            <div v-if="![3,4,5,14].includes(drawer.data.fieldType)" class="mb-5 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                默认值：
              </div>
              <form-item
                v-model="drawer.data.defaultValue"
                class="mr-0"
                :disabled="[7].includes(drawer.data.fieldType)"
                placeholder="默认值"
                type="text"
              />
            </div>
            <template v-else>
              <div v-for="(item,index) in drawer.data.options" :key="index" class=" flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-5">
                  属性名-属性值：
                </div>
                <div class=" flex-y-center">
                  <!-- {{item}} -->
                  <v-checkbox
                    v-model="item.checked"
                    :disabled="drawer.data.customFlag"
                    @change="changeChecked(item,index)"
                  />
                  <input v-model="item.label" class=" default-input" required placeholder="属性名">
                  <span class="mx-1">-</span>
                  <input v-model="item.value" class=" default-input" required placeholder="值">
                  <!-- <form-item class="mr-0" placeholder="属性名" type="text"
                      v-model="item.label"></form-item>
                <span class="mx-1">-</span>
                <form-item class="mr-0" placeholder="属性值" type="text"
                      v-model="item.value"></form-item> -->
                  <v-icon @click="delOptions(index)">mdi-delete-outline</v-icon>
                </div>
              </div>
              <div class="mb-1 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" />
                <a class=" flex-y-center" @click="addOptions">
                  添加
                </a>
              </div>
              <div class="mb-5  flex-y-center">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end" />
                <v-checkbox
                  v-model="drawer.data.customFlag"
                  @change="changeOther"
                />
                <div class="flex-0 flex-shrink-0">
                  选择“其他”可输入文本
                </div>
              </div>
            </template>
          </template>
          <div class="mb-5 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
              <span class="red--text ml-1">*</span>排序：
            </div>
            <form-item
              v-model="drawer.data.sort"
              class="mr-0"
              placeholder="排序"
              required
              type="text"
              pattern="sort"
            />
          </div>
          <div class="mb-5 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
              <span class="red--text ml-1">*</span>是否必填：
            </div>
            <div class=" flex-1">
              <form-item
                v-model="drawer.data.isRequired"
                class=" flex-0 flex-shrink-0"
                :options="[
                  {text:'是',value:'1'},{text:'否',value:'0'}]"
                placeholder="是否必填"
                direction="left"
                required
                dense
                type="radio"
              />
            </div>
          </div>
          <div class="mb-5 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
              <span class="red--text ml-1">*</span>是否列表显示：
            </div>
            <div class=" flex-1">
              <form-item
                v-model="drawer.data.isShow"
                class=" flex-0 flex-shrink-0"
                :options="[
                  {text:'是',value:'1'},{text:'否',value:'0'}]"
                placeholder="是否列表显示"
                direction="left min-width"
                required
                dense
                type="radio"
              />
            </div>
          </div>
          <div class="mb-5 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
              <span class="red--text ml-1">*</span>是否查询：
            </div>
            <div class=" flex-1">
              <form-item
                v-model="drawer.data.isQuery"
                class=" flex-0 flex-shrink-0"
                :options="[
                  {text:'是',value:'1'},{text:'否',value:'0'}]"
                placeholder="是否查询"
                direction="left min-width"
                :disabled="[7].includes(drawer.data.fieldType)"
                required
                dense
                type="radio"
              />
            </div>
          </div>
          <div class="mb-5 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
              <span class="red--text ml-1">*</span>校验唯一性：
            </div>
            <div class=" flex-1">
              <!-- [3, 4, 5, 7, 14].includes(drawer.data.fieldType) -->
              <template v-if="[1,2,3,4,5].includes(drawer.data.fieldCategory)">
                <form-item
                  v-model="drawer.data.isVerify"
                  class=" flex-0 flex-shrink-0"
                  :options="[{text:'系统唯一性',value:'2'},{text:'否',value:'0'}]"
                  placeholder="是否查询"
                  direction="left min-width"
                  required
                  :disabled="[3,4,5,7,8,9,10,11,12,13,14].includes(drawer.data.fieldType)"
                  dense
                  type="radio"
                />
              </template>
              <template v-else>
                <form-item
                  v-model="drawer.data.isVerify"
                  class=" flex-0 flex-shrink-0"
                  :options="[{text:'项目唯一性',value:'1'},{text:'系统唯一性',value:'2'},{text:'否',value:'0'}]"
                  placeholder="是否查询"
                  direction="left min-width"
                  :disabled="[3,4,5,7,8,9,10,11,12,13,14].includes(drawer.data.fieldType)"
                  required
                  dense
                  type="radio"
                />
              </template>
              <!-- <form-item
                v-if="[1,2,3,4,5].includes(drawer.data.fieldCategory)"
                class=" flex-0 flex-shrink-0"
                :options="[{text:'系统唯一性',value:'2'},{text:'否',value:'0'}]"
                placeholder="是否查询"
                direction="left min-width" required
                dense
                type="radio"
                v-model="drawer.data.isVerify"
              ></form-item>
              <form-item
                v-else
                class=" flex-0 flex-shrink-0"
                :options="[{text:'项目唯一性',value:'1'},{text:'系统唯一性',value:'2'},{text:'否',value:'0'}]"
                placeholder="是否查询"
                direction="left min-width" required
                dense
                type="radio"
                v-model="drawer.data.isVerify"
              ></form-item> -->
            </div>
          </div>
          <div v-if="[7].includes(drawer.data.fieldType)" class="mb-5 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
              <span class="red--text ml-1" />同时上传到文件夹：
            </div>
            <div class=" flex-1">
              <v-btn small color="primary" class="mb-2" @click="syncAddShow = true; syncAddTitle = '新增';syncAddData={};">新增</v-btn>
              <v-data-table
                :headers="syncHeaders"
                :items="syncItems"
                class="pb-5 max-width header-grey "
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
                disable-sort
              >
                <template v-slot:item.active="{item,index}">
                  <v-btn small text color="primary" @click="onEditSync(item,index)">编辑</v-btn>
                  <v-btn small text color="primary" @click="onDelSync(item,index)">删除</v-btn>
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
        <div
          class="flex-0 flex-shrink-0 py-3 px-5 flex-justify-end"
          style="border-top:1px solid #e1e1e1;"
        >
          <v-btn width="100" :loading="btnLoading" height="30" class="mr-2" depressed @click="drawer.show = false,drawer.data={}">取消</v-btn>
          <!-- <v-btn type="submit" :loading="btnLoading" height="30" color="primary" width="100" depressed>确定</v-btn> -->
          <div style=" position:relative;">
            <input ref="form" style=" position:absolute;" type="submit" value="submit">
            <v-btn height="30" :loading="btnLoading" color="primary" width="100" depressed @click.stop="subSave">确定</v-btn>
          </div>
        </div>

        <v-dialog v-model="syncAddShow" width="600">
          <v-card>
            <form @submit.prevent="onSyncAddSubmit">
              <v-card-title>{{ syncAddTitle }}</v-card-title>
              <v-card-text>
                <div class="flex-1 flex-column overflow-auto mt-7 px-12">
                  <div class="mb-6 flex-align-start">
                    <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                      <span class="red--text ml-1">*</span>项目/中心文档：
                    </div>
                    <form-item
                      v-model="syncAddData.directoryId"
                      class="mr-0"
                      placeholder="项目/中心文档"
                      required
                      type="select"
                      :options="syncAddSelectData.projectOrCenterDocument"
                      @select="onDocumentChange"
                    />
                  </div>
                  <div class="mb-6 flex-align-start">
                    <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                      <span class="red--text ml-1">*</span>文件夹目录名称：
                    </div>
                    <form-item
                      v-if="syncAddData.directoryId === 1"
                      v-model="syncAddData.categoryId"
                      class="mr-0"
                      placeholder="文件夹目录名称"
                      required
                      type="select"
                      :options="centerSelectList"
                      @select="onFolderDirectoryChoosed"
                    />
                    <form-item
                      v-else
                      v-model="syncAddData.categoryId"
                      class="mr-0"
                      placeholder="文件夹目录名称"
                      required
                      type="select"
                      :options="projectSelectList"
                      @select="onFolderDirectoryChoosed"
                    />
                  </div>
                  <div class="mb-6 flex-align-start">
                    <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                      <span class="red--text ml-1">*</span>路径：
                    </div>

                    <v-menu
                      v-model="information.showMenu"
                      :close-on-content-click="false"
                      bottom
                      max-height="400"
                      offset-overflow
                      transition="slide-y-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <div class=" flex-justify-between flex-y-center" style="width:100%" color="primary" v-on="on" @click="information.search='',information.activeParent=(information.data.folderId?[information.data.folderId]:[])">
                          <form-item
                            v-model="syncAddData.folderPath"
                            class="mr-0"
                            placeholder="路径"
                            required
                            type="text"
                            readonly
                          />
                          <v-icon v-if="information.data.folderId" size="16" @click.stop="information.data.folderId='',syncAddData.folderPath='',information.activeParent=[],$forceUpdate()">mdi-close</v-icon>
                        </div>
                      </template>
                      <v-card class="elevation-0">
                        <div class=" pa-2 white" style="position: sticky;z-index:1;top:0">
                          <form-item
                            v-model="information.search"
                            style="width:300px;"
                            placeholder="筛选"
                            type="text"
                            @input="$forceUpdate()"
                          />
                        </div>
                        <v-treeview
                          class
                          item-children="children"
                          :items="trees"
                          item-text="folderName"
                          :search="information.search"
                          transition
                        >
                          <template v-slot:label="{ item }">
                            <div
                              class="flex-y-center"
                              style="min-height: 38px"
                              @click="item.statusFlag!='1'&&chooseParent(item)"
                            >
                              <div
                                class="max-width max-height flex-y-center user-select"
                                style="min-height: 38px"
                                :title="item.folderName"
                              >
                                <a
                                  class="grey--text text--darken-3 pl-1 max-width max-height flex-y-center"
                                  :class="(information.activeParent.length&&(information.activeParent[0]===item.id))?'light-blue lighten-5':''"
                                  style="min-height: 38px"
                                  :style="{textDecoration:(item.statusFlag=='1')?'line-through':''}"
                                >{{ item.folderName }}
                                </a>
                              </div>
                            </div>
                          </template>
                        </v-treeview>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <div class="max-width flex-y-center flex-justify-between">
                  <div class="flex-1 flex-justify-end">
                    <v-btn
                      width="80"
                      :loading="btnLoading"
                      class="mr-2"
                      depressed
                      height="30"
                      @click="syncAddShow = false"
                    >
                      取消
                    </v-btn>
                    <v-btn
                      width="80"
                      type="submit"
                      :loading="syncSubmitLoading"
                      class
                      height="30"
                      color="primary"
                      depressed
                    >确定</v-btn>
                  </div>
                </div>
              </v-card-actions>
            </form>
          </v-card>
        </v-dialog>
      </form>
    </v-navigation-drawer>
    <listSort ref="listSort" :api="$api.customFieldList" :params="{fieldCategory: search.data.fieldCategory, pageSize: 9999, pageNo: 1 }" @loadList="getList">
      <template slot="content" slot-scope="{ item }">
        <v-col sm="4" md="4" class=" py-2">{{ (item.fieldCategory&&categoryList.filter(_t => item.fieldCategory===_t.id).length)?(categoryList.filter(_t => item.fieldCategory===_t.id)[0].name):'' }}</v-col>
        <v-col sm="3" md="4" class=" py-2">{{ item.fieldName }}</v-col>
        <v-col sm="2" md="2" class=" py-2">
          <span :class="item.statusFlag==1?'error--text':''">
            {{ item.statusFlag==1?'禁用':'正常' }}
          </span>
        </v-col>
      </template>
    </listSort>
  </v-layout>
</template>

<script>
export default {
  name: 'Property',
  data() {
    return {
      syncAddTitle: '',
      trees: [], // 树
      information: {
        showMenu: false,
        activeParent: [],
        data: {}
      },
      syncPathSelectList: [],
      projectSelectList: [],
      centerSelectList: [],
      syncAddSelectData: {
        projectOrCenterDocument: [
          { text: '项目文档', value: 0 }, { text: '中心文档', value: 1 }
        ],
        categoryName: [],
        folderPath: []
      },

      syncAddData: {
        directoryId: '',
        directoryName: '',
        categoryName: '',
        categoryId: '',
        folderPath: '',
        folderId: ''
      },
      syncSubmitLoading: false,
      syncAddShow: false,
      syncHeaders: [
        { text: '项目/中心文档', value: 'directoryName', show: true },
        { text: '文件夹目录名称', value: 'categoryName', show: true },
        { text: '路径', value: 'folderPath', show: true, width: '50px' },
        { text: '操作', value: 'active', sortable: false }],
      syncItems: [],
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {
          fieldCategory: null
        }
      },
      total: 0,
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '属性分类', value: 'fieldCategory', show: true },
        { text: '属性名称', value: 'fieldName', show: true },
        { text: '类型', value: 'fieldType', show: true },
        { text: '排序', value: 'sort', show: true },
        { text: '是否必须', value: 'isRequired', show: true },
        { text: '是否列表显示', value: 'isShow', show: true },
        { text: '是否查询', value: 'isQuery', show: true },
        { text: '校验唯一性', value: 'updated_at', show: true },
        { text: '默认值', value: 'defaultValue', show: true },
        { text: '状态', value: 'statusFlag', show: true },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: [],
      listLoading: false,
      page: 1,
      pageMax: 1,
      selecteds: [],
      btnLoading: false,
      fieldCategory: {
        1: { name: '合作单位-中心', id: 1 },
        2: { name: '合作单位-组织', id: 2 },
        3: { name: '通讯录-中心', id: 3 },
        4: { name: '通讯录-组织', id: 4 },
        5: { name: '项目', id: 5 },
        6: { name: '随访方案', id: 6 },
        7: { name: '参与中心', id: 7 },
        8: { name: '发现问题', id: 8 },
        9: { name: '方案违背', id: 9 },

        11: { name: '报告计划', id: 11 },
        12: { name: '受试者属性', id: 12 },
        13: { name: 'SAE属性', id: 13 },
        14: { name: '合同', id: 14 },
        10: { name: '稽查发现-基本信息', id: 10 },
        15: { name: '稽查发现-执行计划', id: 15 },
        16: { name: '稽查发现-执行结果', id: 16 }
      },
      fieldCategoryArr: [
        { name: '合作单位-中心', id: 1 },
        { name: '合作单位-组织', id: 2 },
        { name: '通讯录-中心', id: 3 },
        { name: '通讯录-组织', id: 4 },
        { name: '项目', id: 5 },
        { name: '随访方案', id: 6 },
        { name: '参与中心', id: 7 },
        { name: '发现问题', id: 8 },
        { name: '方案违背', id: 9 },
        { name: '报告计划', id: 11 },
        { name: '受试者属性', id: 12 },
        { name: 'SAE属性', id: 13 },
        { name: '合同', id: 14 },
        { name: '稽查发现-基本信息', id: 10 },
        { name: '稽查发现-执行计划', id: 15 },
        { name: '稽查发现-执行结果', id: 16 }
      ],
      fieldType: {
        1: { name: '文本', id: 1 },
        2: { name: '数字', id: 2 },
        3: { name: '下拉', id: 3 },
        4: { name: '多选', id: 4 },
        5: { name: '单选', id: 5 },
        6: { name: '多行文本', id: 6 },
        7: { name: '上传附件', id: 7 },
        8: { name: '年月日（YYYY-MM-DD）', id: 8 },
        9: { name: '年份（YYYY）', id: 9 },
        10: { name: '年月（YYYY-MM）', id: 10 },
        11: { name: '日期&时分秒（YYYY-MM-DD HH:MM:SS）', id: 11 },
        12: { name: '时分（HH:MM）', id: 12 },
        13: { name: '时分秒（HH:MM:SS）', id: 13 }
      },
      fieldTypeList: [],
      // 日期&时分（YYYY-MM-DD HH:MM）

      dialog: {
        show: false,
        data: {},
        search: {
          pageSize: 10,
          pageNo: 1,
          fieldCategory: 1,
          data: {}
        },
        total: 0,
        selectedAll: false,
        selected: [],
        headers: [
          { text: '序号', value: 'rowIndex', width: '50', sortable: false },
          { text: '', value: 'checkbox', show: true, sortable: false },
          { text: '项目编号', value: 'projectNumber', show: true },
          { text: '项目名称', value: 'projectName', show: true },
          { text: '项目负责人', value: 'projectManagerName', show: true },
          { text: '里程碑', value: 'milestone', show: true },
          { text: '是否已同步', value: 'syncFlag', show: true },
          { text: '最后同步时间', value: 'syncDate', show: true }
        ],
        list: [],
        showList: [],
        listLoading: false,
        btnLoading: false
      },
      drawer: {
        show: false,
        loading: false,
        data: {},
        fieldTypeShow: false // 类型下拉展示
      },
      fieldOptions: [],
      categoryList: []
    }
  },
  watch: {
    'drawer.data.fieldType'(val) {
    }

    // $route: {
    //   handler(val) {
    //       console.log(val)
    //   },
    //   immediate: true
    // }
  },
  mounted() {
    this.customFieldCategoryList()
    this.customFieldFieldTypeList()
  },
  methods: {
    onDocumentChange(e) {
      this.syncAddData.directoryName = e.t.text
      this.syncAddData.folderPath = ''
      this.syncAddData.folderId = ''
      this.syncAddData.categoryId = ''
      this.syncAddData.categoryName = ''
    },
    onEditSync(item) {
      this.syncAddTitle = '编辑'
      this.syncAddShow = true
      this.onFolderDirectoryChoosed({ val: item.categoryId, t: { text: item.categoryName }}, 'dontClear')
      this.syncAddData = item
      this.syncAddData.categoryId = Number(item.categoryId)
    },
    onDelSync(item, index) {
      this.syncItems.splice(index, 1)
    },

    // 添加文件夹 选择父节点
    chooseParent(e) {
      this.information.activeParent = [e.id]
      this.information.data.folderId = e.id
      this.syncAddData.folderPath = e.folderName
      this.syncAddData.folderId = e.id
      this.information.showMenu = false
    },
    onFolderDirectoryChoosed(e, type) {
      this.syncAddData.categoryName = e.t.text
      const json = {
        catalogueId: e.val,
        folderCategory: this.syncAddData.directoryId
      }
      // json
      this.$http
        .get(this.$api.folderTemplateGetFolderTreeByCatalogueId, { data: json })
        .then(res => {
          // this.syncPathSelectList = res.result || []
          this.trees = res.result || []
          if (!type) {
            this.syncAddData.folderPath = ''
            this.syncAddData.folderId = ''
          }
        })
    },
    onSyncAddSubmit(e) {
      for (const i in this.syncItems) {
        if (this.syncItems[i].folderId === this.syncAddData.folderId) {
          this.$message.error('路径已存在！请更换路径或选择其他文件夹目录。')
          return
        }
      }
      this.syncItems.push(this.syncAddData)
      this.syncAddShow = false
      this.syncAddData = {}
    },
    getSyncSelectList() {
      const json1 = {
        catalogueType: 0, pageNo: 1, pageSize: 999
      }
      const json2 = {
        catalogueType: 1, pageNo: 1, pageSize: 999
      }
      Promise.all([
        this.$http.get(this.$api.projectCatalogueGetProjectCatalogue, { data: json1 }),
        this.$http.get(this.$api.projectCatalogueGetProjectCatalogue, { data: json2 })
      ])
        .then(res => {
          this.projectSelectList = res[0].result.records.map(p => {
            return {
              value: p.id,
              text: p.catalogueName
            }
          }) || []
          this.centerSelectList = res[1].result.records.map(p => {
            return {
              value: p.id,
              text: p.catalogueName
            }
          }) || []
        })
    },
    initList() {
      this.search.pageNo = 1
      this.getList()
      this.getSyncSelectList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getList()
    },

    getList() {
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageSize = this.search.pageSize
      search.pageNo = this.search.pageNo
      this.$http.get(this.$api.customFieldList, { data: { ...search }}).then(res => {
        this.list = res.result.records || []
        this.total = res.result.total || 0
        this.listLoading = false
      })
    },
    // 获取自定义字段-分类列表
    customFieldCategoryList() {
      this.$http.get(this.$api.customFieldCategoryList, { data: null }).then(res => {
        this.categoryList = (res.result && res.result.length) ? res.result.map(item => { return { name: item.name, id: parseInt(item.id) } }) : []
        if (this.categoryList && this.categoryList.length) this.search.data.fieldCategory = this.categoryList[0].id
        this.initList()
      })
    },
    // 自定义字段-自定义类型列表
    customFieldFieldTypeList() {
      this.$http.get(this.$api.customFieldFieldTypeList, { data: null }).then(res => {
        this.fieldTypeList = (res.result && res.result.length) ? res.result.map(item => { return { name: item.name, id: parseInt(item.id) } }) : []
      })
    },
    // 获取前台项目列表
    getQueryProjectList() {
      // this.dialog.search.pageNo = 1
      this.dialog.listLoading = true
      // const param = new URLSearchParams()
      // param.append('categoryId', this.search.data.fieldCategory)
      // param.append('pageNo', this.dialog.search.pageNo)
      // param.append('pageSize', this.dialog.search.pageSize)
      const _params = { ...this.dialog.search.data }
      _params.categoryId = this.search.data.fieldCategory
      _params.pageNo = this.dialog.search.pageNo
      _params.pageSize = this.dialog.search.pageSize
      const param = new URLSearchParams()
      for (const i in _params) {
        param.append(i, (_params[i] || (_params[i] === 0)) ? _params[i] : '')
      }
      // param.append('fieldCategory', this.search.data.fieldCategory)
      // param.append('fieldCategory', this.dialog.search.fieldCategory)
      this.$http.get(this.$api.customFieldQueryProjectList, { data: param }).then(res => {
        // this.dialog.list=res.result?[...res.result]:[]
        // this.dialog.list = res.result ? (JSON.parse(JSON.stringify(res.result))) : []
        // this.dialog.showList = res.result ? [...res.result] : []
        // this.dialog.total = this.dialog.showList.length || 0
        this.dialog.list = (res.result && res.result.records) || []
        this.dialog.total = (res.result && res.result.total) || 0
        this.dialog.selectedAll = false
        this.dialog.selected = []
        this.dialog.listLoading = false
      }).catch(() => {
        this.dialog.list = []
        this.dialog.total = 0
        this.dialog.listLoading = false
      })
    },
    // 同步项目 筛选 不要了
    searchDialogList() {
      let _a = []
      this.dialog.search.pageNo = 1
      // this.dialog.search.data.projectNumber" projectName projectManager
      if (this.dialog.list && this.dialog.list.length) {
        _a = [...this.dialog.list].filter(_r => {
          if ((_r.projectNumber ? (_r.projectNumber.indexOf(this.dialog.search.data.projectNumber || _r.projectNumber) !== -1) : 1) &&
          (_r.projectName ? (_r.projectName.indexOf(this.dialog.search.data.projectName || _r.projectName) !== -1) : 1) &&
          (_r.syncFlag.toString() ? (_r.syncFlag.toString().indexOf(this.dialog.search.data.syncFlag || _r.syncFlag.toString()) !== -1) : 1) &&
          (_r.projectManagerName ? (_r.projectManagerName.indexOf(this.dialog.search.data.projectManagerName || _r.projectManagerName) !== -1) : 1)) {
            return _r
          }
        })
      }
      this.dialog.showList = _a
      this.dialog.total = this.dialog.showList.length || 0
    },
    // 全选
    selectedAll(e) {
      if (e) {
        const _a = []
        // _t.syncFlag
        // _a=this.dialog.showList.map(_t => {
        //   if(_t.syncFlag!=1) return _t.id
        // })
        this.dialog.list.forEach(_t => {
          if ((_t.syncFlag !== '1') && (_a.indexOf(_t.id) === -1)) {
            _a.push(_t.id)
          }
        })
        // _a=this.dialog.showList.map(_t => { return _t.id})
        this.dialog.selected = _a
      } else {
        this.dialog.selected = []
      }
    },
    // 单选
    selectedIt() {
      // let _a=[]
      // this.dialog.showList.forEach(_t => {
      //   if((_t.syncFlag!=1)&&(_a.indexOf(_t.id)==-1)){
      //     _a.push(_t.id)
      //   }
      // })
      // if(_a.length==this.dialog.selected.length){
      //   this.dialog.selectedAll=true
      // }else{
      //   this.dialog.selectedAll=false
      // }
    },
    // 批量同步 属性
    syncProjects(type) {
      // if(confirm("您确定将被选项目的属性进行更新吗？更新后将无法还原同步前状态！")){
      //   this.dialog.btnLoading=true

      //   let param = new URLSearchParams();
      //   param.append('ids', type=='-1'?'-1':this.dialog.selected.join(','))

      //   this.$http.post(this.$api.customFieldSyncProjects,{data:param}).then(res => {
      //       this.dialog.show=false
      //       this.dialog.btnLoading=false
      //       this.dialog.selected=[]
      //       this.dialog.selectedAll=false
      //       this.$message.success(res.message)
      //       // this.getQueryProjectList()

      //   }).catch(()=>{
      //     this.dialog.btnLoading=false
      //   });
      // }

      this.$confirm({ text: '您确定将被选项目的属性进行更新吗？更新后将无法还原同步前状态！' }).then((vm) => {
        this.dialog.btnLoading = true
        const param = new URLSearchParams()
        param.append('ids', type === '-1' ? '-1' : this.dialog.selected.join(','))
        param.append('categoryId', this.search.data.fieldCategory)
        vm.loading = true
        this.$http.post(this.$api.customFieldSyncProjects, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.dialog.show = false
          this.dialog.showList = []
          this.dialog.list = []
          this.dialog.btnLoading = false
          this.dialog.selected = []
          this.dialog.selectedAll = false
          this.$message.success(res.message)
          // this.getQueryProjectList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
          this.dialog.btnLoading = false
        })
      }).catch(() => {})
    },

    // 属性编辑弹窗 通过id查询
    getCustomFieldQueryById(id) {
      this.drawer.data = {}
      this.drawer.loading = true
      this.$http.get(this.$api.customFieldQueryById, { data: { id: id }}).then(res => {
        const data = res.result || {}
        data.customFlag = data.customFlag === '1'
        this.drawer.data = { ...data }
        this.drawer.fieldTypeShow = false
        this.drawer.show = true
        data.syncFolderList.map(p => {
          p.directoryName === '项目文档' ? p.directoryId = 0 : p.directoryId = 1
        })
        this.syncItems = data.syncFolderList
        console.log(data.syncFolderList)
        if (this.drawer.data.options && this.drawer.data.options.length) {
          this.drawer.data.options.forEach(item => {
            item.checked = item.checked === '1'
          })
        }
        this.drawer.loading = false
      }).catch(() => {
        this.drawer.loading = false
      })
    },
    // 添加编辑弹窗 切换属性分类
    changeFieldCategory(id) {
      this.$set(this.drawer.data, 'fieldCategory', id)
      this.$nextTick(() => {
        // 归档文档和签名文档 不 用附件
        if (id && [17, 18].includes(id) && [7].includes(this.drawer.data.fieldType)) {
          this.$set(this.drawer.data, 'fieldType', null)
          this.$set(this.drawer.data, 'isVerify', '')
          this.$set(this.drawer.data, 'isQuery', '')
          this.$set(this.drawer.data, 'defaultValue', '')
        }
        this.$set(this.drawer.data, 'isVerify', [3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14].includes(this.drawer.data.fieldType) ? '0' : '')
        this.$set(this.drawer.data, 'isQuery', [7].includes(this.drawer.data.fieldType) ? '0' : '')
        this.$set(this.drawer.data, 'defaultValue', '')
      })
    },
    // 编辑 添加属性  切换属性类型
    changeType(id) {
      this.drawer.data.customFlag = false
      this.drawer.data.fieldType = id
      this.drawer.data.isVerify = ''
      this.$set(this.drawer.data, 'isQuery', '')
      this.drawer.fieldTypeShow = false

      // if ([3, 4, 5, 14].includes(this.drawer.data.fieldType)) {
      if ([3, 4, 5, 14].includes(this.drawer.data.fieldType)) {
        // drawer.data.options fieldOptions
        // this.drawer.data.isVerify = '0'
        this.$set(this.drawer.data, 'isVerify', '0')
        if (!(this.drawer.data.options && this.drawer.data.options.length)) {
          const options = { checked: '', label: '', value: '' }
          this.drawer.data.options = [options]
        }
      } else {
        // 3,4,5,7,8,9,10,11,12,13,14
        // [7].includes(this.drawer.data.fieldType)
        // if ([7, 8, 9, 10, 11, 12, 13].includes(this.drawer.data.fieldType)) this.drawer.data.isVerify = '0'
        this.$nextTick(() => {
          if ([7].includes(this.drawer.data.fieldType)) {
            this.$set(this.drawer.data, 'isVerify', '0')
            this.$set(this.drawer.data, 'defaultValue', '')
            this.$set(this.drawer.data, 'isQuery', '0')
          } else if ([8, 9, 10, 11, 12, 13].includes(this.drawer.data.fieldType)) {
            this.$set(this.drawer.data, 'isVerify', '0')
          }
          this.drawer.data.options = []
        })
      }
      this.$forceUpdate()
    },
    // 选项添加
    addOptions() {
      const options = { checked: '', label: '', value: '' }
      this.drawer.data.options.push(options)
      this.$forceUpdate()
    },
    // 删除选项
    delOptions(index) {
      this.drawer.data.options.splice(index, 1)
      this.$forceUpdate()
    },
    // 点击提交
    subSave() {
      const u = navigator.userAgent
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.focus()
      this.$nextTick(() => {
        document.body.removeChild(input) // 获取焦点移除节点
        if (u.indexOf('Firefox') > -1) {
          setTimeout(() => {
            this.$refs.form.click()
          }, 300)
        } else {
          this.$refs.form.click()
        }
      })
    },
    // 确认添加 变价 属性
    submit() {
      // let _data={...this.drawer.data}
      const _data = JSON.parse(JSON.stringify(this.drawer.data))
      if (_data.options && _data.options.length) {
        _data.options.forEach(item => {
          item.checked = item.checked ? '1' : '0'
        })
      }
      _data.customFlag = _data.customFlag ? '1' : '0'
      this.btnLoading = true
      const syncFolders = this.syncItems.reduce((acc, item) => {
        return acc + item.folderId + ','
      }, '').slice(0, -1)
      _data.syncFolders = syncFolders
      this.$http.post(this.$api.customFieldSave, { data: { ..._data }}).then(res => {
        this.drawer.show = false
        this.btnLoading = false
        this.fieldOptions = []
        this.syncItems = []
        this.drawer.data = {}
        this.$message.success(res.message)
        this.getList()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    // 确认-禁用-恢复 删除属性
    activeList(url, method, text, params) {
      // if(confirm("是否确认"+text+"?")){
      //   let param = new URLSearchParams()
      //   for(let i in params){
      //     param.append(i, params[i])
      //   }
      //   this.$http[method](url,{data:param}).then(res => {
      //     this.$message.success(res.message)
      //     this.initList()
      //   })
      // }

      this.$confirm({ text: '是否确认' + text + '？' }).then((vm) => {
        const param = new URLSearchParams()
        for (const i in params) {
          param.append(i, params[i])
        }
        vm.loading = true
        this.$http[method](url, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          if (method === 'delete') {
            this.initList()
          } else {
            this.getList()
          }
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 单选多选 默认值
    changeChecked(e, _index) {
      if ([3, 5].includes(this.drawer.data.fieldType) && e.checked) {
        this.drawer.data.options.forEach((item, index) => {
          if (_index !== index) this.$set(item, 'checked', false)
        })
      }
      this.$forceUpdate()
    },
    // 其他
    changeOther(e) {
      // this.drawer.data.other
      if (e) {
        this.drawer.data.options.forEach(item => {
          this.$set(item, 'checked', false)
        })
      }
      this.$forceUpdate()
    }
    // 删除属性
  //   customFieldDelete(id){
  //     if (confirm('确定要删除吗?')) {
  //         this.$http.delete(this.$api.customFieldDelete, {data:{id:id}}).then((res) => {
  //             this.$message.success(res.message)
  //             this.initList()
  //         })
  //     }
  //   }
  },
  head() {
    return {
      title: this.title
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title{
  width:140px;
}
</style>
