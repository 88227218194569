<template>
  <!-- <div class="app"> -->
  <v-app style="background:#f4f5f7;">
    <v-app-bar app class="app-bar" clipped-right color="#fff" fixed flat height="40">
      <!-- border-bottom: 1px dashed #9e9e9e !important -->
      <!-- border-bottom: 1px dashed #ccc !important -->
      <v-sheet
        class="flex-y-center pr-4 max-width"
        color="#fff"
        height="40"
        style=" "
        tile
      >
        <div class="px-4 flex-y-center max-height flex-shrink-0" @click="mini = !mini">
          <v-icon size="18">mdi-format-list-bulleted</v-icon>
        </div>
        <div class="flex-1 overflow-auto">
          <!-- :text-color="tabHistoryActive(item.name)" -->

          <!-- @click:close="closeTab(index)" -->
          <v-chip
            :close="false"
            color="white"
            :text-color="$route.path == '/home' ? 'primary' : 'grey'"
            label
            link
            small
            style="height: 28px"
            class="mr-2 elevation-0"
            outlined
            @click="$router.push('/')"
          >
            <!-- <v-icon left size="16" v-if="item.meta && item.meta.icon">
                {{ item.meta && item.meta.icon }}
              </v-icon> -->
            首页
          </v-chip>
          <template v-for="(item, index) in tabHistory">
            <!-- color="grey lighten-4" -->
            <v-chip
              :key="index"
              close
              color="white"
              :text-color="item.url === $route.path ? 'primary' : 'grey'"
              label
              link
              small
              style="height: 28px"
              class="mr-2 elevation-0"
              outlined
              close-icon="mdi-close"
              @click="$router.push(item.url)"
              @click:close="closeTab(index)"
            >
              <!-- <v-icon left size="16" v-if="item.meta && item.meta.icon">
                {{ item.meta && item.meta.icon }}
              </v-icon> -->
              {{ item.title }}
            </v-chip>
          </template>
        </div>
        <div class="flex-shrink-0 flex-y-center">
          <span class="mr-2">当前版本:</span>
          <v-chip to="/version" small>{{ version }}</v-chip>
        </div>
        <!-- <nav class="flex-y-center overflow-auto flex-grow-1">
            <nuxt-link
              :key="index"
              :to="item.path"
              class="body-2 mr-2"
              v-for="(item, index) in tabHistory"
            >
              <v-chip
                :text-color="tabHistoryActive(item.name)"
                @click:close="closeTab(index)"
                close
                color="grey lighten-4"
                label
                link
                small
                style="height: 28px"
              >
                <v-icon left size="16" v-if="item.meta && item.meta.icon">
                  {{ item.meta && item.meta.icon }}
                </v-icon>
                {{ item.meta && item.meta.title }}
              </v-chip>
            </nuxt-link>
        </nav>-->
        <!-- <v-btn
            class="flex-shrink-0"
            color="primary"
            depressed
            height="28"
            x-small
          >
            关闭所有标签
        </v-btn>-->
      </v-sheet>
    </v-app-bar>
    <!-- v-model="drawer"
        app
    color="#2f49d1"-->
    <!-- 095b9c -->
    <v-navigation-drawer
      color="#095b9c"
      :mini-variant.sync="mini"
      app
      fixed
      permanent
      width="220"
      class=" flex-column flex-justify-between"
    >
      <!-- logo.png -->
      <!-- <div class=" mr-2">
                              <v-avatar color="grey darken-3" size="38">
                                <img class="mr-0" src="@/assets/logo.png" alt="avatar" />
                              </v-avatar>
      </div>-->

      <!-- #4e66e9 -->
      <div class=" flex-0 flex-center" style="border-bottom:1px solid #296696;">
        <a class="flex-y-center  flex-0 flex-shrink-0 logo ml-2 my-5 mr-2 flex-y-center" @click="$router.push('/')">
          <img :height="mini?'24':'30'" class="mr-0" src="@/assets/logo.png">
          <span
            v-if="!mini"
            class="ml-3 mt-1  flex-0 flex-shrink-0"
            style=" font-size:1.1rem; font-weight:500; color: #f3f3f3"
          >后台管理系统</span>
        </a>
      </div>
      <div class=" flex-1 overflow-auto overflow-x-hidden">
        <v-list dense flat nav class="">
          <v-list-item-group v-model="meunActive" color="primary">
            <!-- menu $store.state.menu -->
            <template v-for="(item, index) in $store.state.menu">
              <v-list-group
                v-if="item.children && item.children.length"
                :key="index"
                :class="{ 'mt-2': index }"
                no-action
                :value="(meunActive&&!mini)?('/'+meunActive.toString().split('/')[1]).indexOf(item.url)!=-1:''"
                @click="ttest"
              >

                <!-- (meunActive&&!mini&&meunActive.split('/')&&(meunActive.split('/').length>0))?meunActive.toString().split('/')[1].indexOf(item.router)!=-1:'' -->
                <!-- (meunActive&&!mini)?meunActive.toString().indexOf(item.router)!=-1:'' -->
                <!-- :value="meunActive?meunActive.indexOf(item.router)!=-1:''" -->
                <!-- :value="meunActive.indexOf('/project/'+$route.params.project_id+'/'+item.router)!=-1" -->
                <!-- v-list-group__header v-list-item v-list-item--link theme--light -->
                <template v-slot:activator>
                  <v-list-item-icon class="mr-4">
                    <v-icon color="#f3f3f3" size="18">{{ item.icon||'mdi-shield-check-outline' }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    class="nav_title subtitle-1"
                    style="text-align: left;"
                  >{{ item.name }}</v-list-item-title>
                </template>
                <!-- :to="_item.router"
              nuxt-->
                <!-- :key="_index" -->
                <v-list-item
                  v-for="(_item) in item.children"
                  :key="_item.url"
                  :value="_item.url"
                  :class="(_item.url)==$route.fullPath?'activeMenu':''"
                  @click="_item.name=='菜单管理'?$router.push('/system/menu'):$router.push(_item.url)"
                >
                  <!-- primary--text  -->
                  <v-list-item-icon class="mr-4" />
                  <v-list-item-title
                    class="nav_title subtitle-1"
                    style="min-width: 80px;text-align: left;"
                  >{{ _item.name }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
              <!-- :class="{ 'mt-2': index }" -->
              <v-list-item
                v-else
                :key="item.url"
                :class="(item.url)==$route.fullPath?'primary--text ':''"
                :value="item.url"
                @click="$router.push(item.url)"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon size="18" color="#f3f3f3">{{ item.icon||'mdi-shield-check-outline' }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="nav_title subtitle-1" style="text-align: left;">
                  <!-- {{'/project/'+$route.params.project_id+'/'+item.router}} -->
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
      <!-- #4e66e9 -->
      <div class=" flex-0 flex-y-center pa-3 pl-2 overflow-hidden" style="border-top:1px solid #296696;">
        <v-menu top offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <div class=" flex-y-center mr-4" v-on="on">
              <div class=" flex-0 flex-shrink-0 mr-2">
                <!-- <v-avatar color="grey darken-3" size="40"> -->
                <v-avatar color="white" size="40">
                  <img v-if="userMsg.avatar" class="mr-0" :src="userMsg.avatar" alt="avatar">
                  <img v-else class="mr-0" src="@/assets/head1.png" alt="avatar">
                  <!-- <img class="mr-0" src="@/assets/1.jpg" alt="avatar" />
                        localStorage.getItem('userInfo').avatar -->
                </v-avatar>
              </div>
              <div class=" flex-0 flex-shrink-0 body-2 flex-column" style="color:#eee;">
                <span>{{ userMsg.realname||'-' }}</span>
                <span>{{ userMsg.roleName||'-' }}</span>
              </div>
            </div>
          </template>
          <div class="white">
            <v-btn block depressed tile @click="$router.push('/user')">个人中心</v-btn>
            <v-btn block depressed tile @click="logout">注销</v-btn>
          </div>
        </v-menu>
      </div>
      <!--  -->
    </v-navigation-drawer>
    <template>
      <!-- <v-app-bar
          app
          clipped-left
          color="#1976d2"
          fixed
          flat
          height="50"
          style="z-index: 5"
      >-->

      <!-- <div class="max-width flex-justify-between max-height">
            <div class="flex-y-center nav-list max-height">
              <a class="logo ml-2 mr-2 flex-y-center" @click="$router.push('/')">
                <img height="30" class="mr-0" src="@/assets/logo.png" />
              </a>
              <template v-for="(item, index) in menu">
                <v-menu
                  :key="index"
                  open-on-hover
                  offset-y
                  v-if="item.children && item.children.length"
                >
                  <template v-slot:activator="{ on }">
                    <div class=" flex-x-center max-height" :class="('/'+$route.path.split('/')[1]==item.router)?'activeMeun':''"  v-on="on">
                      <a class="flex-center">
                        <v-icon size="18" class="nav_title">
                          {{item.icon}}
                        </v-icon>
                        <div class=" ml-1 nav_title">{{ item.title }}</div>
                      </a>
                    </div>
                  </template>

                  <v-list dense black>
                    <v-list-item
                      :key="_index"
                      @click="$router.push(_item.router)"
                      v-for="(_item, _index) in item.children"
                      :style="{color:($route.path==_item.router)?'#1976d2':''}"
                    >
                      <v-list-item-icon class="mr-1">
                        <v-icon size="18" class="nav_title" :style="{color:($route.path==_item.router)?'#1976d2':''}">
                          {{_item.icon || 'mdi-account'}}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="nav_title">
                        {{_item.title}}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div class=" flex-x-center max-height" :class="('/'+$route.path.split('/')[1]==item.router||(($route.path)==item.router))?'activeMeun':''" v-else :key="'i_'+index">
                  <a class="flex-center" @click="$router.push(item.router)">
                    <v-icon size="18" class="nav_title">{{ item.icon }}</v-icon>
                    <div class=" ml-1 nav_title">{{ item.title }}</div>
                  </a>
                </div>
              </template>
            </div>
              <v-menu left offset-y open-on-hover>
                    <template v-slot:activator="{ on }">
                            <div class=" flex-y-center mr-4" v-on="on">
                            <div class=" mr-2">
                              <v-avatar color="grey darken-3" size="38">
                                <img class="mr-0" src="@/assets/1.jpg" alt="avatar" />
                              </v-avatar>
                            </div>
                            <div class="body-2 flex-column" style="color:#eee;">
                              <span>徐钊斯</span>
                              <span>副总经理</span>
                            </div>
                            </div>
                    </template>
                    <div class="white">
                        <v-btn block  depressed tile @click="$router.push('/user')">个人中心</v-btn>
                        <v-btn block  depressed tile @click="logout">注销</v-btn>
                    </div>
                </v-menu>
      </div>-->
      <!-- </v-app-bar> -->
    </template>
    <v-main>
      <!-- pt-4 pl-4 -->
      <div class="main-body pa-3">
        <!-- {{meunActive.indexOf(item.router)}} -->
        <!-- <nuxt
            :keep-alive="true"
            :keep-alive-props="{ include: $store.state.keepAlive }"
            class="flex-column"
        />-->
        <router-view />
      </div>
      <!-- Provides the application the proper gutter -->
      <!-- <v-container fluid> -->
      <!-- If using vue-router -->
      <!-- </v-container> -->
    </v-main>
  </v-app>
  <!-- </div> -->
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      drawer: null,
      mini: false,

      menu: [
        // {
        //   title: "部门组织",
        //   icon: "mdi-shield-check-outline",
        //   router: "/department",
        //   color: "#f3f3f3",
        //   children: [
        //     {
        //       title: "角色管理",
        //       icon: "mdi-shield-check-outline",
        //       router: "/department/organizationRole",
        //       color: "#fbf9ff"
        //     },
        //     // {
        //     //   title: "项目角色",
        //     //   icon: "mdi-shield-check-outline",
        //     //   router: "/department/projectRole",
        //     //   color: "#fbf9ff"
        //     // },
        //     {
        //       title: "用户管理",
        //       icon: "mdi-shield-check-outline",
        //       router: "/department/users",
        //       color: "#fbf9ff"
        //     },
        //     {
        //       title: "用户管理",
        //       icon: "mdi-shield-check-outline",
        //       router: "/department/user",
        //       color: "#fbf9ff"
        //     },
        //     // {
        //     //   title: "角色管理",
        //     //   icon: "mdi-shield-check-outline",
        //     //   router: "/department/role",
        //     //   color: "#fbf9ff"
        //     // },
        //     // {
        //     //   title: "部门管理",
        //     //   icon: "mdi-shield-check-outline",
        //     //   router: "/department/department",
        //     //   color: "#fbf9ff"
        //     // }
        //   ]
        // },
        // {
        //   title: "属性配置",
        //   icon: "mdi-view-compact-outline",
        //   router: "/attribute",
        //   color: "#f3f3f3"
        // },
        // {
        //   title: "项目配置",
        //   icon: "mdi-view-compact-outline",
        //   router: "/project",
        //   color: "#f3f3f3",
        //   children: [

        //     {
        //       title: "文件夹",
        //       icon: "mdi-shield-check-outline",
        //       router: "/project/folder",
        //       color: "#fbf9ff"
        //     },
        //     {
        //       title: "进度",
        //       icon: "mdi-shield-check-outline",
        //       router: "/project/progress",
        //       color: "#fbf9ff"
        //     },
        //     {
        //       title: "模板配置",
        //       icon: "mdi-shield-check-outline",
        //       router: "/project/template",
        //       color: "#fbf9ff"
        //     },
        //     {
        //       title: "节点标签",
        //       icon: "mdi-shield-check-outline",
        //       router: "/project/node",
        //       color: "#f3f3f3"
        //     },
        //     {
        //       title: "试验类型",
        //       icon: "mdi-shield-check-outline",
        //       router: "/project/projectClass",
        //       color: "#f3f3f3"
        //     },
        //     {
        //       title: "报告存储路径",
        //       icon: "mdi-shield-check-outline",
        //       router: "/project/reportStoragePath",
        //       color: "#f3f3f3"
        //     }
        //   ]
        // },
        // {
        //   title: "报告配置",
        //   icon: "mdi-view-compact-outline",
        //   router: "/reportConfigure",
        //   color: "#f3f3f3"
        // },
        // {
        //   title: "系统设置",
        //   icon: "mdi-shield-check-outline",
        //   router: "/system",
        //   color: "#f3f3f3",
        //   children: [
        //     {
        //       title: "菜单管理",
        //       icon: "mdi-shield-check-outline",
        //       router: "/system/menu",
        //       color: "#f3f3f3"
        //     },
        //     {
        //       title: "邮件设置",
        //       icon: "mdi-shield-check-outline",
        //       router: "/system/email",
        //       color: "#f3f3f3"
        //     },
        //     {
        //       title: "账号安全设置",
        //       icon: "mdi-shield-check-outline",
        //       router: "/system/security",
        //       color: "#f3f3f3"
        //     },
        //     {
        //       title: "公共信息设置",
        //       icon: "mdi-shield-check-outline",
        //       router: "/system/public",
        //       color: "#f3f3f3"
        //     },
        //     {
        //       title: "操作日志",
        //       icon: "mdi-shield-check-outline",
        //       router: "/system/journal",
        //       color: "#f3f3f3"
        //     }
        //   ]
        // }

        // { title: "表单目录", icon: "mdi-view-compact-outline", router: "/form/category",color:'#8e8e8e' },
        // // { title: "项目管理", icon: "mdi-view-compact-outline", router: "/project",color:'#8e8e8e' },
        // { title: "单位管理", icon: "mdi-shield-check-outline", router: "/partner",color:'#8e8e8e', },
        // { title: "工时管理", icon: "mdi-shield-check-outline", router: "/calendar",color:'#8e8e8e' ,
        //   children:[
        //     {title: "计划工时", icon: "mdi-shield-check-outline", router: "/calendar/list",color:'#8e8e8e'},
        //     {title: "工时填写", icon: "mdi-shield-check-outline", router: "/calendar/calendar",color:'#8e8e8e'}
        //   ]
        // },
        // { title: "公共文档", icon: "mdi-shield-check-outline", router: "/file",color:'#8e8e8e' },
      ],
      meunActive: '',
      tabHistory: [],
      userMsg: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      version: ''
    }
  },
  watch: {
    $route: {
      handler(value) {
        // value.meta.tabHistory &&
        this.meunActive = this.$route.fullPath
        // if (

        //   !this.tabHistory.filter((item) => item.name === value.name).length
        // ) {
        if (

          !this.tabHistory.filter((item) => item.url === value.path).length
        ) {
          // if (value.matched) this.$delete(value, 'matched');
          // this.tabHistory.push({
          //   meta: value.meta,
          //   name: value.name,
          //   title: value.name,
          //   path: value.fullPath,
          //   params: value.params,
          //   query: value.query,
          //   router: value.fullPath,
          //   color: "#f3f3f3"
          // })
          this.$nextTick(() => {
            let _m = {}
            let _f = false
            const _h = (arr) => {
              arr.forEach(item => {
                if (!_f) {
                  if (item.url === value.path) {
                    _f = true
                    _m = {
                      meta: item.meta,
                      name: item.name,
                      title: item.name,
                      path: item.fullPath,
                      params: item.params,
                      query: item.query,
                      router: value.fullPath,
                      url: item.url,
                      color: '#f3f3f3'
                    }
                  } else {
                    if (item.children && item.children.length) _h(item.children)
                  }
                }
              })
            }
            if ((this.$route.path !== '/') && (this.$route.path !== '/home') && (this.$route.path !== '/version')) {
              if (this.$route.path === '/user') {
                _m = {
                  name: '个人中心',
                  title: '个人中心',
                  path: '/user',
                  router: '/user',
                  url: '/user',
                  color: '#f3f3f3'
                }
                this.tabHistory.push(_m)
              } else {
                // this.$store.state.menu
                // _h(this.menu)
                _h(this.$store.state.menu)
                this.tabHistory.push(_m)
              }
            }
          })
          // console.log(JSON.stringify(this.tabHistory))
        }
      }
      // immediate: true
    }
  },
  created() {
    // this.$store.state.menu
    let _m = {}
    let _f = false
    const _h = (arr) => {
      arr.forEach(item => {
        if (!_f) {
          if (item.url === this.$route.path) {
            _f = true
            _m = {
              meta: item.meta,
              name: item.name,
              title: item.name,
              path: item.fullPath,
              params: item.params,
              query: item.query,
              router: item.fullPath,
              url: item.url,
              color: '#f3f3f3'
            }
          } else {
            if (item.children && item.children.length) _h(item.children)
          }
        }
      })
    }

    if ((this.$route.path !== '/') && (this.$route.path !== '/home')) {
      if (this.$route.path === '/user') {
        _m = {

          name: '个人中心',
          title: '个人中心',
          path: '/user',
          router: '/user',
          url: '/user',
          color: '#f3f3f3'
        }
        this.tabHistory.push(_m)
      } else {
        _h(this.$store.state.menu)
        this.tabHistory.push(_m)
      }
    }
    // let param = new URLSearchParams()
    // param.append('permsCategory', '1')
    // this.$http.get(this.$api.getUserPermissionByToken,{data:{permsCategory:'1'}}).then(res => {
    //     // console.log(res)
    //     // let menu=[]
    //     // if(res.result&&res.result.length){
    //     //   res.result.forEach(item=>{
    //     //     menu
    //     //   })
    //     // }
    //     this.menu=res.result||[]

    //     let _m={}
    //     let _f=false
    //     let _h=(arr)=>{
    //       arr.forEach(item=>{
    //         if(!_f){
    //           if(item.url==this.$route.path){
    //             _f=true
    //             _m={
    //               meta: item.meta,
    //               name: item.name,
    //               title: item.name,
    //               path: item.fullPath,
    //               params: item.params,
    //               query: item.query,
    //               router: item.fullPath,
    //               url: item.url,
    //               color: "#f3f3f3"
    //             }
    //           }else{
    //             if(item.children&&item.children.length) _h(item.children)
    //           }
    //         }
    //       })
    //     }

    //     if((this.$route.path!='/')&&(this.$route.path!='/home')){
    //       if(this.$route.path=='/user'){
    //         _m={

    //           name: "个人中心",
    //           title: "个人中心",
    //           path: "/user",
    //           router: "/user",
    //           url: "/user",
    //           color: "#f3f3f3"
    //         }
    //         this.tabHistory.push(_m)
    //       }else{
    //         _h(this.menu)
    //         this.tabHistory.push(_m)
    //       }

    //     }

    // });

    // {
    //       title: "属性配置",
    //       icon: "mdi-view-compact-outline",
    //       router: "/attribute",
    //       color: "#f3f3f3"
    //     },
  },
  mounted() {
    this.meunActive = this.$route.fullPath
    this.$http.get(this.$api.sysVersionQueryNewSysVersion, {}).then(res => {
      this.version = res.result && res.result.showVersionNumber
    })
  },
  methods: {
    logout() {
      this.$confirm({ text: '是否确认退出？' }).then(vm => {
        vm.loading = true
        this.$http.post(this.$api.logout, { data: '' }).then(() => {
          vm.show = false
          vm.loading = false
          localStorage.removeItem('access_token')
          localStorage.removeItem('userInfo')
          this.$router.replace({ path: '/login' })
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
      // this.$http.post(this.$api.logout, { data: '' }).then(() => {
      //   localStorage.removeItem('access_token')
      //   localStorage.removeItem('userInfo')
      //   this.$router.replace({ path: '/login' })
      // })
    },
    ttest() {
      this.meunActive = this.meunActive ? this.meunActive : null
      this.mini = false
    },
    closeTab(_index) {
      // this.tabHistory.delete(index)
      this.tabHistory.forEach((item, index) => {
        if (item.url === this.$route.path) {
          if (index === _index) {
            // this.$route.path()
            this.$router.push(this.tabHistory[index - 1].url)
          }
        }
      })
      this.tabHistory.splice(_index, 1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.nav-list {
  flex: 1;
  display: flex;
  height: 100%;
  .activeMeun {
    background: #1e88e5;
  }
  .nav_title {
    // color: #8e8e8e;
    color: #fbf9ff !important;
    // color: #eee !important;
    transition: all 0.35s ease;
  }

  a {
    display: flex;
    align-items: center;
    padding: 0 22px;
    margin-right: 2px;
    // font-size: 13px;
    // color: #8e8e8e;
    // color: #fff;
    transition: all 0.35s ease;

    &.nuxt-link-active,
    &:hover {
      // background: #9e9e9e;
      color: #eee;
    }

    .icon {
      margin-right: 10px;
    }
  }
}
.nav_title {
  color: #f3f3f3 !important;
}

::v-deep .v-item--active.v-list-item--active {
  background: #2774b1 !important;
  // #576cd9
}
::v-deep .v-list-item__icon.v-list-group__header__append-icon {
  color: rgba(255, 255, 255, 0.54) !important;
  .theme--light.v-icon {
    color: rgba(255, 255, 255, 0.54) !important;
  }
}
::v-deep .v-list--nav .v-list-item{
  padding:0 10px !important;
  &:hover{
    background: #2774b1 !important;
  }
}
.activeMenu{
  background: #2774b1 !important;
}
::v-deep .v-chip__close{
 font-size: 16px !important;
}
</style>
