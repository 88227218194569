var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:" flex-justify-end flex-center",attrs:{"sm":"2","xl":"2","md":"2","lg":"2"}},[_vm._v(" 财务管理文件同步： ")]),_c('v-col',{attrs:{"sm":"8","xl":"8","md":"8","lg":"8"}},[_c('form-item',{staticClass:" flex-0 flex-shrink-0",staticStyle:{"width":"250px"},attrs:{"options":[{text:'是',value:1},{text:'否',value:0}],"dense":"","type":"radio"},on:{"input":_vm.onSyncFlagChange},model:{value:(_vm.syncFlag),callback:function ($$v) {_vm.syncFlag=$$v},expression:"syncFlag"}})],1)],1),(_vm.syncFlag)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"flex-justify-end flex-center",attrs:{"sm":"2","xl":"2","md":"2","lg":"2"}},[_vm._v(" 文件夹权限： ")]),_c('v-col',{attrs:{"sm":"8","xl":"8","md":"8","lg":"8"}},[_c('form-item',{staticClass:"lex-0 flex-shrink-0",staticStyle:{"width":"320px"},attrs:{"options":_vm.folderPermissions,"dense":"","type":"select"},on:{"input":_vm.onFolderPermissionChange},model:{value:(_vm.folderPermissionsId),callback:function ($$v) {_vm.folderPermissionsId=$$v},expression:"folderPermissionsId"}})],1)],1):_vm._e(),(_vm.syncFlag)?_c('v-row',[_c('v-col',{attrs:{"sm":"2","xl":"2","md":"2","lg":"2"}}),_c('v-col',{attrs:{"sm":"8","xl":"8","md":"8","lg":"8"}},[_c('v-data-table',{staticClass:"pb-5 max-width header-grey",attrs:{"headers":_vm.headers,"items":_vm.list.slice((_vm.search.pageSize*(_vm.search.pageNo-1)),(_vm.search.pageSize*_vm.search.pageNo)),"items-per-page":_vm.search.pageSize,"hide-default-footer":"","item-key":"id","no-data-text":"暂无查询数据","loading":_vm.listLoading},scopedSlots:_vm._u([{key:"item.rowIndex",fn:function(ref){
var index = ref.index;
return [_vm._v(_vm._s((index+1)+(_vm.search.pageSize*(_vm.search.pageNo-1))))]}},(!_vm.listLoading)?{key:"item.hidden",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"dense":"","hide-details":""},model:{value:(_vm.permissionsObj[item.id].pem),callback:function ($$v) {_vm.$set(_vm.permissionsObj[item.id], "pem", $$v)},expression:"permissionsObj[item.id].pem"}},[_c('v-radio',{attrs:{"value":1}})],1)]}}:null,(!_vm.listLoading)?{key:"item.readonly",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"dense":"","hide-details":""},model:{value:(_vm.permissionsObj[item.id].pem),callback:function ($$v) {_vm.$set(_vm.permissionsObj[item.id], "pem", $$v)},expression:"permissionsObj[item.id].pem"}},[_c('v-radio',{attrs:{"value":2}})],1)]}}:null,(!_vm.listLoading)?{key:"item.writableUser",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"dense":"","hide-details":""},model:{value:(_vm.permissionsObj[item.id].pem),callback:function ($$v) {_vm.$set(_vm.permissionsObj[item.id], "pem", $$v)},expression:"permissionsObj[item.id].pem"}},[_c('v-radio',{attrs:{"value":4}})],1)]}}:null,(!_vm.listLoading)?{key:"item.writable",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"dense":"","hide-details":""},model:{value:(_vm.permissionsObj[item.id].pem),callback:function ($$v) {_vm.$set(_vm.permissionsObj[item.id], "pem", $$v)},expression:"permissionsObj[item.id].pem"}},[_c('v-radio',{attrs:{"value":3}})],1)]}}:null],null,true)}),_c('div',{staticClass:"mt-2 pr-2 mb-5 max-width flex-justify-end"},[_c('pagination',{attrs:{"size":_vm.search.pageSize,"total":_vm.total},model:{value:(_vm.search.pageNo),callback:function ($$v) {_vm.$set(_vm.search, "pageNo", $$v)},expression:"search.pageNo"}})],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"sm":"2","xl":"2","md":"2","lg":"2"}}),_c('v-col',{attrs:{"sm":"8","xl":"8","md":"8","lg":"8"}},[_c('v-btn',{staticClass:"mr-2",staticStyle:{"padding":"0 8px"},attrs:{"small":"","color":"primary","depressed":"","min-width":"120","height":"30"},on:{"click":_vm.saveProgressLimit}},[_vm._v("保存")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }