<template>
  <v-layout max-width class="max-height flex-column flex-justify-between">
    <div class="flex-0 flex-shrink-0 flex-y-center px-2 white mb-2" style>
      <!-- @change="active=[trees[0].id]" -->

      <div v-if="!showMainContent" class=" flex-0 pt-2">
        <v-tabs v-model="tab" @change="loadList()">
          <v-tab :href="`#0`" class=" py-2">项目文件夹</v-tab>
          <v-tab :href="`#1`" class=" py-2">中心文件夹</v-tab>
        </v-tabs>
      </div>
      <v-btn
        v-if="showMainContent"
        color="#00bea9"
        depressed
        dark
        small
        style="padding:2px 8px"
        min-width="30"
        height="30"
        width="100"
        class=" mr-4"
        @click="syncDialog.selectedAll=false,syncDialog.selected=[],syncDialog.search.pageNo=1,syncDialog.search.data={},syncDialog.show=true,searchSyncDialogList()"
      >
        文件夹同步
      </v-btn>
      <v-btn
        v-if="showMainContent"
        color="#00bea9"
        depressed
        dark
        small
        style="padding:2px 8px"
        min-width="30"
        height="30"
        width="100"
        class=" mr-4"
        @click="showMainContent = false, loadList(), catalogueId = ''"
      >
        返回目录
      </v-btn>
    </div>
    <div v-if="!showMainContent">
      <div>
        <v-btn
          color="primary"
          depressed
          small
          height="30"
          style="padding:0 8px"
          min-width="30"
          class="mr-2 mb-2"
          @click="fileDialog.show = true, catalogueName = ''"
        >
          <v-icon size="18">mdi-plus</v-icon>新增目录
        </v-btn>
        <div v-if="Number(tab)===0" class=" overflow-auto" style="width:70%">
          <v-data-table
            :headers="fileHeaders"
            :items="fileList"
            :items-per-page="fileSearch.pageSize"
            class="pb-5 max-width header-grey"
            hide-default-footer
            item-key="id"
            no-data-text="暂无查询数据"
            :loading="fileListLoading"
            disable-sort
          >
            <template v-slot:item.rowIndex="{index}">
              {{ (index+1)+(fileSearch.pageSize*(fileSearch.pageNo-1)) }}
            </template>
            <template v-slot:item.catalogueName="{item}">
              <div class=" font-overflow" style="max-width:500px;">{{ item.catalogueName }}</div>
            </template>
            <template v-slot:item.statusFlag="{item}">
              <div v-if="item.statusFlag == '1'" style="color: red">禁用</div>
              <div v-else>可用</div>
            </template>
            <template v-slot:item.action="{item}">
              <div class=" flex-y-center">
                <v-icon color="primary" size="18" title="配置" class=" mr-3" @click="catalogueId = item.id, getList()">mdi-view-dashboard-outline</v-icon>
                <v-icon color="primary" size="18" title="编辑" class=" mr-3" @click="fileDialog.show = true, editId = item.id, catalogueName = item.catalogueName ">mdi-square-edit-outline</v-icon>
                <v-icon
                  v-if="item.statusFlag == '0'"
                  color="error"
                  size="20"
                  title="禁用"
                  class=" mr-3"
                  @click="activeListStatus($api.projectCatalogueSetProjectCatalogueStatus,'post',item,{id:item.id, statusFlag:'1'})"
                >
                  mdi-cancel
                </v-icon>
                <v-icon
                  v-else
                  color="primary"
                  size="20"
                  title="恢复"
                  class=" mr-3"
                  @click="activeListStatus($api.projectCatalogueSetProjectCatalogueStatus,'post',item,{id:item.id, statusFlag:'0'})"
                >
                  mdi-replay
                </v-icon>
                <v-icon color="error" size="20" title="删除" class=" mr-3" @click="activeListDelete($api.projectCatalogueDelete,'delete','删除',{id:item.id})">mdi-delete-outline</v-icon>
              </div>
            </template>
          </v-data-table>
        </div>
        <div v-if="Number(tab)===0" class="mt-2 pr-2 mb-5 max-width flex-justify-start">
          <pagination
            v-model="fileSearch.pageNo"
            :size="fileSearch.pageSize"
            :total="fileTotal"
            @input="loadList"
            @changePageSize="fileChangePageSize"
          />
        </div>
        <div v-if="Number(tab)===1" class=" overflow-auto" style="width:70%">
          <v-data-table
            :headers="fileHeaders"
            :items="fileList"
            :items-per-page="fileSearch.pageSizeb"
            class="pb-5 max-width header-grey"
            hide-default-footer
            item-key="id"
            no-data-text="暂无查询数据"
            :loading="fileListLoading"
            disable-sort
          >
            <template v-slot:item.rowIndex="{index}">
              {{ (index+1)+(fileSearch.pageSizeb*(fileSearch.pageNob-1)) }}
            </template>
            <template v-slot:item.catalogueName="{item}">
              <div class=" font-overflow" style="max-width:500px;">{{ item.catalogueName }}</div>
            </template>
            <template v-slot:item.statusFlag="{item}">
              <div v-if="item.statusFlag == '1'" style="color: red">禁用</div>
              <div v-else>可用</div>
            </template>
            <template v-slot:item.action="{item}">
              <div class=" flex-y-center">
                <v-icon color="primary" size="18" title="配置" class=" mr-3" @click="catalogueId = item.id, getList()">mdi-view-dashboard-outline</v-icon>
                <v-icon color="primary" size="18" title="编辑" class=" mr-3" @click="fileDialog.show = true, editId = item.id, catalogueName = item.catalogueName ">mdi-square-edit-outline</v-icon>
                <v-icon color="error" size="20" title="禁用" class=" mr-3" @click="activeListStatus($api.projectCatalogueSetProjectCatalogueStatus,'post',item,{id:item.id, statusFlag:item.statusFlag == '1'? '0' : '1'})">mdi-cancel</v-icon>
                <v-icon color="error" size="20" title="删除" class=" mr-3" @click="activeListDelete($api.projectCatalogueDelete,'delete','删除',{id:item.id})">mdi-delete-outline</v-icon>
              </div>
            </template>
          </v-data-table>
        </div>
        <div v-if="Number(tab)===1" class="mt-2 pr-2 mb-5 max-width flex-justify-start">
          <pagination
            v-model="fileSearch.pageNob"
            :size="fileSearch.pageSizeb"
            :total="fileTotal"
            @input="loadList"
            @changePageSize="fileChangePageSizeb"
          />
        </div>
      </div>
    </div>
    <!-- style="border-top:1px solid #e8e8e8 !important" white -->
    <div class="flex-1 flex-justify-start flex-justify-between overflow-hidden">
      <!-- <div class="flex-1 flex-justify-between max-height"> -->
      <!-- -->
      <div
        v-if="showMainContent"
        class="flex-0 flex-shrink-0 flex-column overflow-hidden white"
        style="width:320px; border-right:1px solid #e8e8e8 !important;"
      >
        <div class="flex-0 flex-y-center flex-wrap mt-3 px-3 mb-1">
          <v-btn
            color="primary"
            depressed
            small
            height="30"
            style="padding:0 8px"
            min-width="30"
            class="mr-2 mb-2"
            @click="dialog.parentName='',dialog.activeParent=[],dialog.data={commonPath:'0',allowSubFolder:'0'},dialog.show=true"
          >
            <v-icon size="18">mdi-plus</v-icon>添加文件夹
          </v-btn>
          <template v-if="treeActive&&treeActive.length">
            <v-btn
              color="error"
              depressed
              dark
              small
              height="30"
              style="padding:0 8px;"
              min-width="30"
              class="mr-2 mb-2"
              @click="activeList($api.folderTemplateDelete,'delete','删除',{id:treeActive[0]})"
            >
              <v-icon size="18">mdi-delete-outline</v-icon>删除文件夹
            </v-btn>
            <v-btn
              v-if="information.data.statusFlag=='1'"
              color="#00bea9"
              depressed
              dark
              small
              height="30"
              style="padding:0 8px;"
              min-width="60"
              class="mr-2 mb-2"
              @click="setDialog.data={folderId:treeActive[0], statusFlag: '0'},setDialog.show=true"
            >
              <!-- activeList($api.folderTemplateSetFolderStatus,'post','恢复',{folderId:treeActive[0], statusFlag: '0'}) -->
              <!--  -->
              <v-icon size="18" class="mr-1">mdi-replay</v-icon>恢复
            </v-btn>
            <v-btn
              v-else
              color="error"
              depressed
              dark
              small
              height="30"
              style=" padding:0 8px;"
              min-width="60"
              class="mr-2 mb-2"
              @click="activeList($api.folderTemplateSetFolderStatus,'post','禁用',{folderId:treeActive[0], statusFlag: '1'})"
            >
              <v-icon size="18" class="mr-1">mdi-cancel</v-icon>禁用
            </v-btn>
            <!-- <v-btn
              color="#00bea9"
              depressed
              dark
              small
              style="height:32px; padding:0;"
              min-width="30"
              class="ml-2"
            >
              <v-icon size="20">mdi-reply-outline</v-icon>
            </v-btn>-->
          </template>
          <!-- folderTemplateQueryProjectList -->

        </div>
        <div class="flex-1 px-3 overflow-auto">
          <!-- :active.sync="active"
            @update:active="chooseTree"
            activatable -->
          <v-treeview
            class
            item-children="children"
            :items="trees"
            :open.sync="open"
            item-text="folderName"
            transition
          >
            <template v-slot:label="{ item }">
              <div
                class="flex-y-center"
                style="min-height: 38px"
                @click="updateActive(item)"
              >
                <div
                  class="max-width max-height flex-y-center user-select"
                  style="min-height: 38px"
                  :title="item.folderName"
                >
                  <!-- information.data.statusFlag=='1' -->
                  <a
                    class="grey--text text--darken-3 pl-1 max-width max-height flex-y-center"
                    :class="(treeActive.length&&(treeActive[0]===item.id))?'light-blue lighten-5':''"
                    style="min-height: 38px"
                    :style="{textDecoration:(item.statusFlag=='1')?'line-through':''}"
                  >{{ item.folderName }}
                  </a>
                </div>
              </div>
            </template>
          </v-treeview>
          <!-- selection-type="independent"
              selectable
          v-model="selection"-->
        </div>
      </div>
      <div v-if="showMainContent" class="flex-1 flex-column flex-justify-between overflow-hidden white">
        <!-- border-bottom -->
        <v-tabs
          v-model="rightTab"
          class="flex-0"
          style="border-bottom:1px solid #e8e8e8 !important"
          @change="changeTab"
        >
          <v-tab :href="`#1`">基本信息</v-tab>
          <v-tab :href="`#2`">权限</v-tab>
          <v-tab :href="`#3`">文件模板</v-tab>
        </v-tabs>
        <!-- <div></div> -->
        <v-tabs-items
          v-if="treeActive&&treeActive.length"
          v-model="rightTab"
          class="flex-1 overflow-auto"
        >
          <v-tab-item class="mt-12 ml-2" :value="`1`">
            <form @submit.prevent="submit('edit')">
              <div class="mb-8 flex-align-start">
                <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>文件夹名称：
                </div>
                <form-item
                  v-model="information.data.folderName"
                  style="width:300px;"
                  placeholder="文件夹名称"
                  :disabled="information.data.statusFlag=='1'"
                  required
                  type="text"
                  @keyup="(e)=>{changeFolderName(e,'information')}"
                />
              </div>
              <div class="mb-8 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">父级文件夹：</div>
                <form-item
                  v-if="information.data.statusFlag=='1'"
                  v-model="information.parentName"
                  style="width:300px;"
                  placeholder="父级文件夹"
                  disabled
                  type="text"
                />
                <v-menu
                  v-else
                  v-model="information.showMenu"
                  :close-on-content-click="false"
                  bottom
                  max-height="400"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class="default-input flex-justify-between flex-y-center" style="width:300px !important;" color="primary" v-on="on" @click="information.search='',information.activeParent=(information.data.parentId?[information.data.parentId]:[])">
                      <input
                        v-model="information.parentName"
                        placeholder="父级文件夹"
                        style="width:300px;"
                        type="text"
                        readonly
                      >
                      <v-icon v-if="information.data.parentId" size="16" @click.stop="information.data.parentId='',information.parentName='',information.activeParent=[],$forceUpdate()">mdi-close</v-icon>
                    </div>
                  </template>
                  <v-card class="elevation-0">
                    <div class=" pa-2 white" style="position: sticky;z-index:1;top:0">
                      <form-item
                        v-model="information.search"
                        style="width:300px;"
                        placeholder="筛选"
                        type="text"
                        @input="$forceUpdate()"
                      />
                    </div>
                    <v-treeview
                      class
                      item-children="children"
                      :items="trees"
                      item-text="folderName"
                      :search="information.search"
                      transition
                    >
                      <template v-slot:label="{ item }">
                        <div
                          class="flex-y-center"
                          style="min-height: 38px"
                          @click="item.statusFlag!='1'&&chooseParent('edit',item)"
                        >
                          <div
                            class="max-width max-height flex-y-center user-select"
                            style="min-height: 38px"
                            :title="item.folderName"
                          >
                            <a
                              class="grey--text text--darken-3 pl-1 max-width max-height flex-y-center"
                              :class="(information.activeParent.length&&(information.activeParent[0]===item.id))?'light-blue lighten-5':''"
                              style="min-height: 38px"
                              :style="{textDecoration:(item.statusFlag=='1')?'line-through':''}"
                            >{{ item.folderName }}
                            </a>
                          </div>
                        </div>
                      </template>
                    </v-treeview>
                  </v-card>
                </v-menu>
              </div>
              <!-- <div class="mb-8 flex-align-start">
                <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  上级任务：
                </div>
                <v-menu
                  :close-on-content-click="false"
                  bottom
                  max-height="300"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                  v-model="information.showMenu"
                >
                  <template v-slot:activator="{ on }">
                    <div style="width:300px !important;" class="default-input" color="primary" v-on="on">
                      <input
                        placeholder="上级任务"
                        disabled
                        class="max-width"
                        type="text"
                        v-model="information.parentName"
                      />
                    </div>
                  </template>
                  <v-card class=" elevation-0">
                  <v-treeview
                    class=""
                    :active.sync="information.activeParent"
                    @update:active="chooseParent"
                    item-children="children"
                    activatable
                    :items="trees"
                    item-text="folderName"
                    transition
                    return-object
                  ></v-treeview>
                  </v-card>
                </v-menu>
              </div> -->
              <!-- folderTemplateMoveFolder -->
              <div class="mb-12 flex-align-start">
                <div
                  class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                  style="width:170px;"
                >
                  <span class="red--text ml-1">*</span>排序：
                </div>
                <form-item
                  v-model="information.data.sort"
                  style="width:300px;"
                  placeholder="排序"
                  :disabled="information.data.statusFlag=='1'"
                  required
                  type="number"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div
                  class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                  style="width:170px;"
                >
                  <span class="red--text ml-1">*</span>通用文件路径：
                </div>
                <form-item
                  v-model="information.data.commonPath"
                  style="width:300px;"
                  required
                  type="radio"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                />
              </div>
              <div class="mb-12 flex-align-start">
                <div
                  class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                  style="width:170px;"
                >
                  <span class="red--text ml-1">*</span>允许前台创建子文件夹：
                </div>
                <form-item
                  v-model="information.data.allowSubFolder"
                  style="width:300px;"
                  required
                  type="radio"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                />
              </div>
              <div class="mb-10 flex-align-start">
                <div
                  class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                  style="width:170px;"
                />
                <v-btn v-if="information.data.statusFlag!=='1'" type="submit" height="30" :loading="btnLoading" color="primary" depressed dark width="100">保存</v-btn>
              </div>
            </form>
          </v-tab-item>
          <v-tab-item :value="`2`">
            <v-data-table
              :headers="headers"
              :items="list.slice((search.pageSize*(search.pageNo-1)),(search.pageSize*search.pageNo))"
              :items-per-page="search.pageSize"
              class="pb-5 max-width header-grey"
              hide-default-footer
              item-key="id"
              no-data-text="暂无查询数据"
              :loading="listLoading"
            >
              <template
                v-slot:item.rowIndex="{index}"
              >{{ (index+1)+(search.pageSize*(search.pageNo-1)) }}</template>
              <template v-slot:item.hidden="{item}">
                <v-radio-group
                  v-model="permissionsObj[item.id].pem"
                  :disabled="information.data.statusFlag==='1'"
                  dense
                  hide-details
                  class="mt-0"
                >
                  <v-radio :value="1" @click="(e)=>{changePermissions(e,1,item.id)}" />
                </v-radio-group>
              </template>
              <template v-slot:item.readonly="{item}">
                <v-radio-group
                  v-model="permissionsObj[item.id].pem"
                  :disabled="information.data.statusFlag==='1'"
                  dense
                  hide-details
                  class="mt-0"
                >
                  <v-radio :value="2" @change="(e)=>{changePermissions(e,2,item.id)}" />
                </v-radio-group>
              </template>
              <template v-slot:item.writableUser="{item}">
                <v-radio-group
                  v-model="permissionsObj[item.id].pem"
                  :disabled="information.data.statusFlag==='1'"
                  dense
                  hide-details
                  class="mt-0"
                >
                  <v-radio :value="4" @change="(e)=>{changePermissions(e,4,item.id)}" />
                </v-radio-group>
              </template>
              <template v-slot:item.writable="{item}">
                <v-radio-group
                  v-model="permissionsObj[item.id].pem"
                  :disabled="information.data.statusFlag==='1'"
                  dense
                  hide-details
                  class="mt-0"
                >
                  <v-radio :value="3" @change="(e)=>{changePermissions(e,3,item.id)}" />
                </v-radio-group>
              </template>
            </v-data-table>
            <div class="mt-2 pr-2 mb-5 max-width flex-justify-end">
              <pagination
                v-model="search.pageNo"
                :size="search.pageSize"
                :total="total"
                @changePageSize="(e)=>{search.pageSize=e,search.pageNo=1,$forceUpdate()}"
              />
            </div>
            <div class="mt-10 flex-justify-end">
              <!-- <v-btn @click="submitPermission" color="primary" depressed dark width="100">保存</v-btn> -->
              <v-dialog v-if="information.data.statusFlag!='1'" v-model="permissionDialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="btnLoading"
                    color="primary"
                    depressed
                    dark
                    width="100"
                    height="30"
                    class="mr-6"
                    v-bind="attrs"
                    @click="appliedToSubfolders=''"
                    v-on="on"
                  >保存</v-btn>
                </template>
                <v-card>
                  <v-card-title class="font-weight-bold">保存权限</v-card-title>
                  <v-divider />
                  <v-card-text class="py-5 body-1">
                    <div class="my-10 flex-center">
                      <div class="flex-1 flex-justify-end">应用到所有子文件夹：</div>
                      <form-item
                        v-model="appliedToSubfolders"
                        class="flex-1"
                        :options="[{text:'是',value:'1'},{text:'否',value:'2'}]"
                        dense
                        type="radio"
                      />
                    </div>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn width="80" height="30" class depressed @click="permissionDialog = false">取消</v-btn>
                    <v-btn
                      width="80"
                      :loading="btnLoading"
                      class="ml-3"
                      color="primary"
                      height="30"
                      depressed
                      @click.stop="submitPermission"
                    >确定</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-tab-item>
          <!-- 模板start -->
          <v-tab-item :value="`3`" eager>
            <vueTemplate ref="vueTemplate" :folder-id="treeActive[0]" />
          </v-tab-item>
        </v-tabs-items>
      </div>
      <!-- </div> -->
    </div>

    <!-- 编辑 添加文件夹start -->
    <v-dialog v-model="dialog.show" width="600">
      <v-card>
        <form @submit.prevent="submit()">
          <v-card-title class="font-weight-bold">添加文件夹</v-card-title>
          <v-divider />
          <v-card-text class="py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>文件夹名称：
                </div>
                <form-item
                  v-model="dialog.data.folderName"
                  class="mr-0"
                  placeholder="文件夹名称"
                  required
                  type="text"
                  @keyup="(e)=>{changeFolderName(e,'dialog.data')}"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">父级文件夹：</div>
                <v-menu
                  v-model="dialog.showMenu"
                  :close-on-content-click="false"
                  bottom
                  max-height="400"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class="default-input flex-justify-between flex-y-center" color="primary" v-on="on" @click="dialog.activeParent=(dialog.data.parentId?[dialog.data.parentId]:[])">
                      <input
                        v-model="dialog.parentName"
                        placeholder="父级文件夹"
                        class="max-width"
                        type="text"
                        readonly
                      >
                      <v-icon v-if="dialog.data.parentId" size="16" @click.stop="dialog.data.parentId='',dialog.parentName='',dialog.activeParent=[],$forceUpdate()">mdi-close</v-icon>
                    </div>
                  </template>
                  <v-card class="elevation-0">
                    <!-- dialog.data.parentId=(dialog.activeParent.length?dialog.activeParent[0]:'') -->
                    <!-- item.statusFlag=='1' -->
                    <!-- <v-treeview
                      class
                      :active.sync="dialog.activeParent"
                      item-children="children"
                      activatable
                      :items="trees"
                      item-text="folderName"
                      transition
                      return-object
                      @update:active="chooseParent"
                    /> -->
                    <v-treeview
                      class
                      item-children="children"
                      :items="trees"
                      item-text="folderName"
                      transition
                    >
                      <template v-slot:label="{ item }">
                        <div
                          class="flex-y-center"
                          style="min-height: 38px"
                          @click="item.statusFlag!='1'&&chooseParent('add',item)"
                        >
                          <div
                            class="max-width max-height flex-y-center user-select"
                            style="min-height: 38px"
                            :title="item.folderName"
                          >
                            <a
                              class="grey--text text--darken-3 pl-1 max-width max-height flex-y-center"
                              :class="(dialog.activeParent.length&&(dialog.activeParent[0]===item.id))?'light-blue lighten-5':''"
                              style="min-height: 38px"
                              :style="{textDecoration:(item.statusFlag=='1')?'line-through':''}"
                            >{{ item.folderName }}
                            </a>
                          </div>
                        </div>
                      </template>
                    </v-treeview>
                  </v-card>
                </v-menu>
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>排序：
                </div>
                <form-item
                  v-model="dialog.data.sort"
                  class="mr-0"
                  placeholder="排序"
                  required
                  type="number"
                />
              </div>
              <div class="mb-4 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>通用文件路径：
                </div>
                <form-item
                  v-model="dialog.data.commonPath"
                  class="mr-0"
                  required
                  type="radio"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>允许前台创建子文件夹：
                </div>
                <form-item
                  v-model="dialog.data.allowSubFolder"
                  class="mr-0"
                  required
                  type="radio"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn
                  width="80"
                  :loading="btnLoading"
                  class="mr-2"
                  depressed
                  height="30"
                  @click="dialog.show = false,dialog.data={}"
                >取消</v-btn>
                <v-btn
                  width="80"
                  type="submit"
                  :loading="btnLoading"
                  class
                  height="30"
                  color="primary"
                  depressed
                >确定</v-btn>
              </div>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <!-- 编辑 添加文件夹 end -->

    <!-- 恢复 文件夹start -->
    <v-dialog v-model="setDialog.show" width="400">
      <v-card>
        <form @submit.prevent="submitSetDialog()">
          <v-card-title class="font-weight-bold">恢复文件夹</v-card-title>
          <v-divider />
          <v-card-text class="py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-5" style="width:150px;">
                  <span class="red--text ml-1">*</span>是否恢复子文件夹：
                </div>
                <v-checkbox
                  v-model="setDialog.data.subFlag"
                  :hide-details="false"
                />
                <!-- <form-item
                  class="mr-0"
                  placeholder="排序"
                  required
                  type="number"
                  v-model="dialog.data.sort"
                ></form-item> -->
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn
                  width="80"
                  :loading="btnLoading"
                  class="mr-2"
                  depressed
                  height="30"
                  @click="setDialog.show = false,setDialog.data={}"
                >取消</v-btn>
                <v-btn
                  width="80"
                  type="submit"
                  :loading="btnLoading"
                  class
                  height="30"
                  color="primary"
                  depressed
                >确定</v-btn>
              </div>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <!--  恢复 文件夹 end -->

    <!-- 同步文件夹start -->
    <v-dialog v-model="syncDialog.show" width="1250" max-width="100%">
      <v-card class="flex-column flex-justify-between" style="height:740px;">
        <v-card-title class="flex-0 font-weight-bold">前台文件夹同步</v-card-title>
        <v-divider />
        <v-card-text class="flex-1 px-0 body-1 overflow-hidden">
          <div class="flex-column mb-3 max-width max-height">
            <div class="flex-0 flex-justify-between flex-y-center pl-3">
              <div class="flex-y-center">
                <v-btn
                  class="mr-2"
                  color="primary"
                  :loading="syncDialog.btnLoading"
                  depressed
                  width="100"
                  height="30"
                  @click="syncProjects('-1')"
                >全部同步</v-btn>
                <v-btn
                  :disabled="!syncDialog.selected.length"
                  color="primary"
                  :loading="syncDialog.btnLoading"
                  depressed
                  width="100"
                  height="30"
                  @click="syncProjects"
                >批量同步</v-btn>
              </div>
              <div class="flex-justify-end px-3 py-2">
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="syncDialog.search.data.projectNumber"
                    class="mr-0"
                    placeholder="项目编号"
                    type="text"
                    allow-close
                    @input="$forceUpdate()"
                    @onEnter="searchSyncDialogList()"
                  />
                </div>
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="syncDialog.search.data.projectName"
                    class="mr-0"
                    placeholder="项目名称"
                    type="text"
                    allow-close
                    @input="$forceUpdate()"
                    @onEnter="searchSyncDialogList()"
                  />
                </div>
                <div class="flex-y-center mr-1" style="width:120px;">
                  <!-- projectManagerName -->
                  <form-item
                    v-model="syncDialog.search.data.nick"
                    class="mr-0"
                    placeholder="项目负责人"
                    type="text"
                    allow-close
                    @input="$forceUpdate()"
                    @onEnter="searchSyncDialogList()"
                  />
                </div>
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="syncDialog.search.data.syncFlag"
                    class="flex-1 flex-shrink-0"
                    :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                    required
                    placeholder="是否已同步"
                    dense
                    type="select"
                    allow-close
                    @input="$forceUpdate()"
                    @select="(e)=>{syncDialog.search.data.syncFlag = e.val,searchSyncDialogList()}"
                    @cleanVal="(e)=>{syncDialog.search.data.syncFlag = e.val,searchSyncDialogList()}"
                  />
                </div>
                <v-btn
                  dark
                  small
                  color="primary"
                  depressed
                  min-width="34"
                  height="30"
                  style=" padding:0;"
                  @click="searchSyncDialogList()"
                >
                  <v-icon size="18">mdi-magnify</v-icon>
                </v-btn>
                <v-btn
                  dark
                  small
                  color="grey lighten-1"
                  class="mx-1"
                  title="重置筛选"
                  depressed
                  min-width="34"
                  height="30"
                  style="padding:0;"
                  @click="syncDialog.search.data={},searchSyncDialogList()"
                >
                  <v-icon size="18">mdi-restore</v-icon>
                </v-btn>
              </div>
            </div>

            <div class="flex-column flex-1 overflow-auto">
              <v-data-table
                :headers="syncDialog.headers"
                :items="syncDialog.list"
                :items-per-page="syncDialog.search.pageSize"
                class="pb-5 max-width header-grey"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
                :loading="syncDialog.listLoading"
                disable-sort
                dense
              >
                <template v-slot:header.checkbox>
                  <v-checkbox
                    v-model="syncDialog.selectedAll"
                    class="mt-0 py-2"
                    dense
                    label="全选"
                    hide-details
                    @change="(e)=>{selectedAll(e,'left')}"
                  />
                </template>
                <template
                  v-slot:item.rowIndex="{index}"
                >{{ (index+1)+(syncDialog.search.pageSize*(syncDialog.search.pageNo-1)) }}</template>
                <template v-slot:item.checkbox="{item}">
                  <v-checkbox
                    v-if="!['1','2'].includes(item.syncFlag)"
                    v-model="syncDialog.selected"
                    class="mt-0 pt-0"
                    dense
                    hide-details
                    :value="item.id"
                    @change="selectedIt()"
                  />
                </template>
                <template v-slot:item.projectName="{item}">
                  <div class=" font-overflow" style="max-width:400px;" :title="item.projectName">
                    {{ item.projectName }}
                  </div>
                </template>
                <template
                  v-slot:item.syncFlag="{item}"
                >{{ item.syncFlag==1?'是':(item.syncFlag==2?'同步中':(item.syncFlag==0?'否':'')) }}</template>
              </v-data-table>
              <div class="mt-2 pr-2 mb-5 max-width flex-justify-end">
                <pagination
                  v-model="syncDialog.search.pageNo"
                  :size="syncDialog.search.pageSize"
                  :total="syncDialog.total"
                  @input="getQueryProjectList()"
                  @changePageSize="(e)=>{syncDialog.search.pageSize=e,syncDialog.search.pageNo=1,$forceUpdate(),getQueryProjectList()}"
                />
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="flex-0 ">
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="100" height="30" :loading="btnLoading" class="mr-2" depressed @click="syncDialog.show = false,syncDialog.data={},syncDialog.selectedAll=false,syncDialog.selected=[]">取消</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 同步文件夹 end -->

    <v-dialog v-model="fileDialog.show" width="600">
      <v-card>
        <form @submit.prevent="addCatalogueName()">
          <v-card-title class="font-weight-bold">{{ editId? '编辑目录' : '新增目录' }}</v-card-title>
          <v-divider />
          <v-card-text class="py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>文件目录名称:
                </div>
                <form-item
                  v-model="catalogueName"
                  class="mr-0"
                  placeholder="文件目录名称"
                  required
                  type="text"
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn
                  width="80"
                  :loading="filebtnLoading"
                  class="mr-2"
                  depressed
                  height="30"
                  @click="fileDialog.show = false,catalogueName = ''"
                >取消</v-btn>
                <v-btn
                  width="80"
                  type="submit"
                  :loading="filebtnLoading"
                  class
                  height="30"
                  color="primary"
                  depressed
                >确定</v-btn>
              </div>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import vueTemplate from './folder/template.vue'
export default {
  components: { vueTemplate },
  data() {
    return {
      appliedToSubfolders: '',
      permissions: [],
      permissionsObj: {},
      tab: '0',
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0, // 权限总条数
      active: [], // 选中的树的节点id
      treeActive: [],
      trees: [], // 树
      open: [], // 打开的节点
      rightTab: null, // 右侧选中的tab
      information: {
        showMenu: false,
        activeParent: [],
        data: {}
      }, // 选中的树的节点信息
      old_information: {},
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '项目角色', value: 'roleName' },
        { text: '隐藏', value: 'hidden' },
        { text: '只读', value: 'readonly' },
        { text: '可写(本人)', value: 'writableUser' },
        { text: '可写(所有)', value: 'writable' }
      ],
      list: [],
      listLoading: false,
      jurisdiction: {
        readonly: [],
        writable: []
      },
      selected: [],
      dialog: {
        // 添加文件夹弹窗
        show: false,
        type: '',
        showMenu: false,
        activeParent: [],
        data: {}
      },
      setDialog: {
        // 恢复文件夹弹窗
        show: false,
        btnLoading: false,
        data: {}
      },
      selection: [],
      btnLoading: false,
      permissionDialog: false,
      syncDialog: {
        // 同步文件夹弹窗
        show: false,
        data: {},
        search: {
          pageSize: 10,
          pageNo: 1,
          fieldCategory: 1,
          data: {}
        },
        total: 0,
        selectedAll: false,
        selected: [],
        headers: [
          { text: '序号', value: 'rowIndex', width: '50', sortable: false },
          { text: '选择', value: 'checkbox', show: true, sortable: false },
          { text: '项目编号', value: 'projectNumber', show: true },
          { text: '项目名称', value: 'projectName', show: true },
          { text: '项目负责人', value: 'projectManagerName', show: true },
          { text: '里程碑', value: 'mileStone', show: true },
          { text: '是否已同步', value: 'syncFlag', show: true },
          { text: '最后同步时间', value: 'syncDate', show: true }
        ],
        list: [],
        listLoading: false,
        btnLoading: false
      },
      // lanyijian
      showMainContent: false,
      fileSearch: {
        pageSize: 10,
        pageNo: 1,
        pageSizeb: 10,
        pageNob: 1,
        data: {}
      },
      fileTotal: 0, // 权限总条数
      fileHeaders: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '文件目录名称', value: 'catalogueName' },
        { text: '状态', value: 'statusFlag' },
        { text: '操作', value: 'action' }
      ],
      fileList: [],
      fileListLoading: false,
      fileDialog: {
        show: false
      },
      catalogueName: '',
      filebtnLoading: false,
      catalogueId: '', // 列表每一行的目录的id
      editId: '' // 编辑获取每一行的目录的id
    }
  },
  mounted() {
    // this.getList()
    this.loadList()
    this.getRoleList()
  },
  methods: {
    // 新增目录
    addCatalogueName() {
      this.filebtnLoading = true
      if (this.editId) {
        const param = new URLSearchParams()
        param.append('catalogueName', this.catalogueName)
        param.append('id', this.editId)
        this.$http
          .post(this.$api.projectCatalogueUpdateProjectCatalogue, { data: param })
          .then(res => {
            console.log('目录编辑', res)
            this.$message.success(res.message)
            this.filebtnLoading = false
            this.fileDialog.show = false
            this.loadList()
          }).catch(() => {
            this.filebtnLoading = false
          })
      } else {
        const param = new URLSearchParams()
        param.append('catalogueName', this.catalogueName)
        param.append('catalogueType', this.tab)
        this.$http
          .post(this.$api.projectCatalogueAddProjectCatalogue, { data: param })
          .then(res => {
            console.log('目录新增', res)
            this.$message.success(res.message)
            this.filebtnLoading = false
            this.fileDialog.show = false
            this.loadList()
          }).catch(() => {
            this.filebtnLoading = false
          })
      }
    },
    // 获取文件目录列表数据
    loadList(e) {
      console.log('此时的tab', this.tab)
      this.fileListLoading = true
      const param = new URLSearchParams()
      param.append('catalogueType', this.tab)
      if (Number(this.tab) === 0) {
        param.append('pageNo', this.fileSearch.pageNo)
        param.append('pageSize', this.fileSearch.pageSize)
      }
      if (Number(this.tab) === 1) {
        param.append('pageNo', this.fileSearch.pageNob)
        param.append('pageSize', this.fileSearch.pageSizeb)
      }
      this.$http
        .get(this.$api.projectCatalogueGetProjectCatalogue, { data: param })
        .then(res => {
          console.log('目录列表', res)
          this.fileList = (res.result && res.result.records) || []
          this.fileTotal = (res.result && res.result.total) || 0
          this.fileListLoading = false
        }).catch(() => {
          this.fileListLoading = false
        })
    },
    // 分页
    fileChangePageSize(e) {
      this.fileSearch.pageSize = e
      this.fileSearch.pageNo = 1
      this.$forceUpdate()
      this.loadList()
    },
    // 分页b
    fileChangePageSizeb(e) {
      this.fileSearch.pageSizeb = e
      this.fileSearch.pageNob = 1
      this.$forceUpdate()
      this.loadList()
    },
    // 列表项禁用
    activeListStatus(url, method, item, params) {
      const title = Number(item.statusFlag) ? '恢复' : '禁用'
      this.$confirm({ text: '是否确认' + title + '当前文件目录？' })
        .then(vm => {
          const param = new URLSearchParams()
          for (const i in params) {
            param.append(i, params[i] || '')
          }
          vm.loading = true
          this.$http[method](url, { data: param })
            .then(res => {
              vm.show = false
              vm.loading = false
              this.$message.success(res.message)
              this.loadList()
            })
            .catch(() => {
              vm.show = false
              vm.loading = false
            })
        })
        .catch(() => {})
    },
    // 列表项删除
    activeListDelete(url, method, title, params) {
      this.$confirm({ text: '是否确认' + title + '当前文件目录？' })
        .then(vm => {
          const param = new URLSearchParams()
          for (const i in params) {
            param.append(i, params[i] || '')
          }
          vm.loading = true
          this.$http[method](url, { data: param })
            .then(res => {
              vm.show = false
              vm.loading = false
              this.$message.success(res.message)
              this.loadList()
            })
            .catch(() => {
              vm.show = false
              vm.loading = false
            })
        })
        .catch(() => {})
    },
    // lanyijian endLine------

    // 文件夹-获取目录树
    getList(type) {
      this.showMainContent = true
      const param = new URLSearchParams()
      param.append('folderCategory', this.tab)
      param.append('catalogueId', this.catalogueId)
      this.$http
        .get(this.$api.folderTemplateGetFolderTreeByCatalogueId, { data: param })
        .then(res => {
          this.trees = res.result || []
          if (type === 'edit') {
            // this.chooseTree(this.active)
          } else {
            if (this.trees && this.trees.length) {
              // this.active = [this.trees[0].id];
              this.treeActive = [this.trees[0].id]
              // this.chooseTree(this.active);
              this.updateActive(this.trees[0])
            } else {
              this.active = []
              this.treeActive = []
              this.information.data = {} // 选中节点的内容
              this.old_information = {}
              this.appliedToSubfolders = ''
              this.permissionsObj = {}
              this.rightTab = '1'
              this.search.pageNo = 1
            }
          }
        })
    },
    getRoleList() {
      this.total = 0
      this.listLoading = true
      const search = { roleCategory: '1' }
      // search.pageNo=this.search.pageNo
      // search.pageSize=this.search.pageSize
      search.pageNo = 1
      search.pageSize = 999
      const param = new URLSearchParams()
      for (const i in search) {
        param.append(i, search[i] || '')
      }
      this.$http
        .get(this.$api.roleList, { data: { ...search }})
        .then(res => {
          this.listLoading = false
          this.list = (res.result && res.result.records) || []
          this.total = (res.result && res.result.total) || 0
        })
        .catch(() => {
          this.listLoading = false
        })
    },
    // 获取权限
    folderTemplateGetFolderPermission() {
      const param = new URLSearchParams()
      param.append('folderId', this.treeActive[0])
      this.listLoading = true
      this.$http
        .get(this.$api.folderTemplateGetFolderPermission, { data: param })
        .then(res => {
          this.listLoading = false
          this.appliedToSubfolders = res.result.appliedToSubfolders
          const permissions = (res.result && res.result.permissions) || []
          const _obj = {}
          permissions.forEach(item => {
            _obj[item.roleId] = { ...item }
          })
          const permissionsObj = {}
          this.list.forEach(item => {
            permissionsObj[item.id] = { pem: 1, roleId: item.id }
            if (_obj[item.id]) {
              permissionsObj[item.id] = { ..._obj[item.id] }
            }
          })
          this.permissionsObj = permissionsObj
        }).catch(() => {
          this.listLoading = false
        })
    },
    // 选中左侧树节点
    updateActive(e) {
      this.treeActive = [e.id]
      this.information.data = { ...e }
      this.old_information = { ...e }
      let _parentName = ''
      let _f = false
      this.$forceUpdate()
      this.folderTemplateGetFolderPermission()
      this.$nextTick(() => {
        this.$refs.vueTemplate && this.$refs.vueTemplate.initList()
      })
      const _h = (arr) => {
        arr.forEach(item => {
          if (!_f) {
            if (item.id === e.parentId) {
              _f = true
              _parentName = item.folderName
            } else {
              if (item.children && item.children.length) {
                _h(item.children)
              }
            }
          }
        })
      }
      _h(this.trees)
      this.old_information.parentName = _parentName
      this.information.parentName = _parentName
    },
    // 左侧树 点击 不用了
    chooseTree(e) {
      this.rightTab = '1'
      this.search.pageNo = 1
      if (e && e.length) {
        this.folderTemplateGetFolderPermission()
        this.$nextTick(() => {
          this.$refs.vueTemplate && this.$refs.vueTemplate.initList()
        })
        let _information = {}
        let _f = false
        const _h = arr => {
          arr.forEach(item => {
            if (!_f) {
              if (item.id === e[0]) {
                _f = true
                _information = item
              } else {
                if (item.children && item.children.length) {
                  _h(item.children)
                }
              }
            }
          })
        }
        _h(this.trees)
        this.information.data = { ..._information } // 选中节点的内容
        this.old_information = { ..._information }
      } else {
        this.information.data = {}
        this.old_information = {}
      }
    },
    // 确认 删除文件夹
    activeList(url, method, title, params) {
      this.$confirm({ text: '是否确认' + title + '文件夹？' })
        .then(vm => {
          const param = new URLSearchParams()
          for (const i in params) {
            param.append(i, params[i] || '')
          }
          vm.loading = true
          this.$http[method](url, { data: param })
            .then(res => {
              vm.show = false
              vm.loading = false
              this.$message.success(res.message)
              this.treeActive = []
              this.information.data = {}
              this.old_information = {}
              this.getList()
            })
            .catch(() => {
              vm.show = false
              vm.loading = false
            })
        })
        .catch(() => {})
    },
    // 添加文件夹 选择父节点
    chooseParent(type, e) {
      if (type === 'add') {
        this.dialog.activeParent = [e.id]
        this.dialog.data.parentId = e.id
        this.dialog.parentName = e.folderName
        this.dialog.showMenu = false
      } else {
        this.information.activeParent = [e.id]
        this.information.data.parentId = e.id
        this.information.parentName = e.folderName
        this.information.showMenu = false
      }
      // if (e && e.length) {
      //   if (this.dialog.show) {
      //     this.dialog.data.parentId = e[0].id
      //     this.dialog.parentName = e[0].folderName
      //     this.dialog.showMenu = false
      //   } else {
      //     // information.roleIds
      //     this.information.data.parentId = e[0].id
      //     this.information.parentName = e[0].folderName
      //     this.information.showMenu = false
      //   }
      //   // this.dialog.data.parentId = e[0].id;
      //   // this.dialog.parentName = e[0].folderName;
      //   // this.dialog.showMenu = false;
      // } else {
      //   if (this.dialog.show) {
      //     this.dialog.data.parentId = ''
      //     this.dialog.parentName = ''
      //   } else {
      //     this.information.data.parentId = ''
      //     this.information.parentName = ''
      //   }
      //   // this.dialog.data.parentId = "";
      //   // this.dialog.parentName = "";
      // }
    },
    // 确认新增文件夹
    submit(type) {
      let url = this.$api.folderTemplateAdd
      let method = 'post'
      let _data = {}
      if (type === 'edit') {
        // 编辑
        url = this.$api.folderTemplateEdit
        method = 'put'
        _data = { ...this.information.data, folderCategory: Number(this.tab) + Number(1), catalogueId: this.catalogueId } // 改动
      } else {
        _data = { ...this.dialog.data, folderCategory: Number(this.tab) + Number(1), catalogueId: this.catalogueId }
      }
      this.btnLoading = true
      this.$http[method](url, { data: { ..._data }})
        .then(res => {
          this.$message.success(res.message)
          this.dialog.show = false
          this.dialog.data = {}
          this.btnLoading = false
          if (type === 'edit') {
            this.old_information.parentId = _data.parentId
            this.old_information.parentName = this.information.parentName
            this.getList('edit')
          } else {
            this.getList()
          }
        })
        .catch(() => {
          this.btnLoading = false
          if (type === 'edit') {
            this.information.data = { ...this.old_information }
            this.information.parentName = this.information.data.parentName
          }
        })
    },
    // 确认恢复文件夹
    submitSetDialog() {
      // activeList($api.folderTemplateSetFolderStatus,'post','恢复',{folderId:treeActive[0], statusFlag: '0'})
      const param = new URLSearchParams()
      const params = { ...this.setDialog.data, subFlag: !!this.setDialog.data.subFlag }
      for (const i in params) {
        if (i === 'subFlag') {
          param.append(i, this.setDialog.data.subFlag ? '1' : '0')
        } else {
          param.append(i, params[i] || '')
        }
      }
      this.setDialog.btnLoading = true
      this.$http.post(this.$api.folderTemplateSetFolderStatus, { data: param })
        .then(res => {
          this.$message.success(res.message)
          this.setDialog.btnLoading = false
          this.setDialog.show = false
          this.setDialog.data = {}
          this.treeActive = []
          this.information.data = {}
          this.old_information = {}
          this.getList()
        })
        .catch(() => {
        })
    },
    changePermissions(e, type, params) {
      // console.log(e);
      // console.log(type);
      // console.log(params);
      // console.log(a);
      // console.log(b);
      this.permissions.forEach(item => {
        if (item.roleId === params) {
          item.pem = type
          if (e) {
            // 选中
          } else {
            // 取消选中
          }
        }
      })
    },
    // 确认保存权限
    submitPermission() {
      const permissions = []
      for (const i in this.permissionsObj) {
        permissions.push(this.permissionsObj[i])
      }
      const param = {
        appliedToSubfolders: this.appliedToSubfolders,
        folderId: this.treeActive[0],
        permissions: permissions
      }
      this.btnLoading = true
      this.$http
        .post(this.$api.folderTemplateSaveFolderPermission, { data: param })
        .then(res => {
          this.$message.success(res.message)
          this.permissionDialog = false
          this.btnLoading = false
        })
        .catch(() => {
          this.permissionDialog = false
          this.btnLoading = false
          this.folderTemplateGetFolderPermission()
        })
    },
    // 获取前台项目列表 初始化页码
    searchSyncDialogList() {
      this.syncDialog.search.pageNo = 1
      this.getQueryProjectList()
    },
    // 获取前台项目列表
    getQueryProjectList() {
      const search = { ...this.syncDialog.search.data }
      search.pageNo = this.syncDialog.search.pageNo
      search.pageSize = this.syncDialog.search.pageSize
      const param = new URLSearchParams()
      for (const i in search) {
        param.append(i, search[i] || '')
      }
      param.append('catalogueId', this.catalogueId)
      // param.append('categoryId', this.tab)
      this.syncDialog.listLoading = true
      this.$http
        .get(this.$api.folderTemplateQueryProjectList, { data: param })
        .then(res => {
          this.syncDialog.list = (res.result && res.result.records) || []
          this.syncDialog.total = (res.result && res.result.total) || 0
          this.syncDialog.listLoading = false
          this.syncDialog.selectedAll = false
          this.syncDialog.selected = []
        })
        .catch(() => {
          this.syncDialog.list = []
          this.syncDialog.total = 0
          this.syncDialog.selectedAll = false
          this.syncDialog.selected = []
          this.syncDialog.listLoading = false
        })
    },
    // 同步文件夹 全选
    selectedAll(e) {
      if (e) {
        const _a = []
        this.syncDialog.list.forEach(_t => {
          if (!['1', '2'].includes(_t.syncFlag) && (_a.indexOf(_t.id) === -1)) {
            _a.push(_t.id)
          }
        })
        this.syncDialog.selected = _a
      } else {
        this.syncDialog.selected = []
      }
    },
    // 同步文件夹 单选
    selectedIt() {
      const _a = []
      this.syncDialog.list.forEach(_t => {
        if ((_t.syncFlag !== '1') && (_a.indexOf(_t.id) === -1)) {
          _a.push(_t.id)
        }
      })
      if (_a.length === this.syncDialog.selected.length) {
        this.syncDialog.selectedAll = true
      } else {
        this.syncDialog.selectedAll = false
      }
    },
    // 同步文件夹 批量同步 全部同步
    syncProjects(type) {
      this.$confirm({
        text: '您确定同步文件夹吗？同步后将无法还原同步前状态！'
      })
        .then(vm => {
          this.syncDialog.btnLoading = true
          const param = new URLSearchParams()
          param.append(
            'ids',
            type === '-1' ? '-1' : this.syncDialog.selected.join(',')
          )
          // param.append('categoryId', this.tab)
          param.append('catalogueId', this.catalogueId)
          vm.loading = true
          this.$http
            .post(this.$api.folderTemplateSyncProjects, { data: param })
            .then(res => {
              vm.show = false
              vm.loading = false
              this.syncDialog.show = false
              this.syncDialog.btnLoading = false
              this.syncDialog.selected = []
              this.syncDialog.selectedAll = false
              this.$message.success(res.result || res.message)
            })
            .catch(() => {
              vm.show = false
              vm.loading = false
              this.syncDialog.btnLoading = false
            })
        })
        .catch(() => {})
    },
    changeFolderName(e, type) {
      if (e) {
        if (type === 'dialog.data') {
          this.$set(this.dialog.data, 'folderName', e.replace(/[:"<>/?\\\\*|]/g, ''))
        } else {
          this.$set(this.information.data, 'folderName', e.replace(/[:"<>/?\\\\*|]/g, ''))
        }
        // this.$set(this[type],'folderName',e.replace(/[:"<>/?\\\\*|]/g,""))
      }
      this.$forceUpdate()
    },
    changeTab(e) {
      if (e === '3') {
        this.$nextTick(() => {
          this.$refs.vueTemplate && this.$refs.vueTemplate.initList()
        })
      }
    }
    // rightTab"
    //       class="flex-0"
    //       style="border-bottom:1px solid #e8e8e8 !important"
    //       @change="changeTab"
  }
}
</script>

<style lang="scss" scoped>
.form-title {
  width: 170px;
  color: #777;
}
.red {
  color: red;
}
</style>
