<template>
  <v-layout class="flex-column pb-6" style="min-height:100%;">
    <div class="flex-0 flex-shrink-0 flex-y-center flex-justify-between white px-2 mb-2">
      <v-tabs v-model="tab">
        <v-tab :href="`#0`">添加项目数限制</v-tab>
        <v-tab :href="`#1`">归档文件上传限制</v-tab>
        <v-tab :href="`#2`">回收站文件保留天数</v-tab>
        <!-- <v-tab :href="`#3`">奖金发放流程</v-tab> -->
        <v-tab :href="`#4`">项目进度</v-tab>
        <v-tab :href="`#5`">稽查配置</v-tab>
        <v-tab :href="`#6`">方案解读</v-tab>
        <v-tab :href="`#7`">工时配置</v-tab>
        <v-tab :href="`#8`">SAE</v-tab>
        <v-tab :href="`#9`">财务管理文件同步</v-tab>
      </v-tabs>
    </div>
    <div class=" flex-1 flex-column py-2 white">
      <template v-if="tab === '0'">
        <div class=" flex-y-center px-4 pb-2">
          <span class=" flex-0 flex-shrink-0">前台用户添加项目限制：</span>
          <form-item
            v-model="project.data.isLimit"
            class=" flex-0 flex-shrink-0"
            :options="[{text:'是',value:1},{text:'否',value:0}]"
            style="width: 150px"
            dense
            type="radio"
            @input="$forceUpdate()"
          />
          <v-btn v-if="project.data.isLimit !== project.isLimit" small color="primary" depressed min-width="60" class="mr-2" height="30" style="padding:0 8px" @click="saveProjectStatus">保存</v-btn>
        </div>
        <template v-if="project.isLimit === 1">
          <div class=" flex-y-center flex-justify-between px-4 pt-2" style="border-top:1px solid #e8e8e8">
            <div class=" flex-y-center">
              <v-btn dark small color="#00bea9" depressed min-width="32" class="mr-2" height="30" style="padding:0 8px" @click="addShow">
                <v-icon size="18">mdi-plus</v-icon>新增人员
              </v-btn>
              <exportBtn :url="$api.exportProjectLimit" :parmas="{...search.data}" />
            </div>
            <div class=" flex-y-center">
              <div class="flex-y-center mr-1" style="width:120px;">
                <form-item
                  v-model="search.data.searchUserName"
                  class=" flex-0 flex-shrink-0"
                  placeholder="用户账号"
                  dense
                  allow-close
                  type="text"
                  @input="$forceUpdate()"
                  @onEnter="initList()"
                />
              </div>
              <div class="flex-y-center mr-1" style="width:120px;">
                <form-item
                  v-model="search.data.searchRealName"
                  class="mr-0"
                  placeholder="用户姓名"
                  type="text"
                  allow-close
                  @input="$forceUpdate()"
                  @onEnter="initList()"
                />
              </div>
              <div class="flex-y-center mr-1" style="width:120px;">
                <form-item
                  v-model="search.data.searchStatus"
                  class=" flex-0 flex-shrink-0"
                  :options="[{text:'有效',value:'0'},{text:'禁用',value:'1'},{text:'无效',value:'2'}]"
                  placeholder="状态"
                  style="width: 120px"
                  dense
                  allow-close
                  type="select"
                  @input="$forceUpdate()"
                  @select="(e)=>{search.data.searchStatus = e.val,initList()}"
                  @cleanVal="(e)=>{search.data.searchStatus = e.val,initList()}"
                />
              </div>
              <v-btn dark small color="primary" class="mr-1" depressed min-width="34" height="30" style="padding:0;" @click="initList()">
                <v-icon size="18">mdi-magnify</v-icon>
              </v-btn>
              <v-btn dark small color="grey lighten-1" depressed min-width="34" height="30" style="padding:0;" @click="search.data={},initList()">
                <v-icon size="18">mdi-restore</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="">
            <v-data-table
              :headers="headers"
              :items="list"
              :items-per-page="search.pageSize"
              class="pb-5 max-width  pa-4 header-grey"
              hide-default-footer
              :loading="listLoading"
              item-key="id"
              no-data-text="暂无查询数据"
              disable-sort
            >
              <!-- <template v-slot:item="{expand,item}"> -->
              <template v-slot:item.rowIndex="{index}">
                {{ (index+1)+(search.pageSize*(search.pageNo-1)) }}
              </template>
              <template v-slot:item.projectUsed="{item}">
                <a @click="projectDialog.realName = item.realName,projectDialog.id = item.id,projectDialog.createBy=item.createBy,projectDialog.search.pageNo=1,showProject(item)">{{ item.projectUsed }}</a>
              </template>
              <template v-slot:item.startTime="{item}">
                {{ (item.startTime||'-') + '~' + (item.endTime||'-') }}
              </template>
              <template v-slot:item.statusFlag="{item}">
                <!-- 0-有效 1-禁用 -->
                <span v-if="item.statusFlag==='1'" class="error--text">禁用</span>
                <span v-else-if="item.endTime&&(new Date().getTime()>new Date(item.endTime).getTime())" class="error--text">失效</span>
                <span v-else>有效</span>
              </template>
              <template v-slot:item.active="{item}">
                <div class=" flex-y-center">
                  <v-icon :disabled="(item.statusFlag==='1')" color="primary" size="18" title="编辑" class=" mr-1" @click="showEdit(item)">mdi-square-edit-outline</v-icon>
                  <v-icon v-if="item.statusFlag==='1'" color="primary" size="18" class=" mr-1" title="恢复" @click="activeList($api.projectLimitSetStatus,'post','恢复',{id:item.id,status:'0'})">mdi-replay</v-icon>
                  <v-icon v-else :disabled="item.endTime&&(new Date().getTime()>new Date(item.endTime).getTime())" color="error" size="18" class=" mr-1" title="禁用" @click="activeList($api.projectLimitSetStatus,'post','禁用',{id:item.id,status:'1'})">mdi-cancel</v-icon>
                  <v-icon color="error" size="20" title="删除" class=" mr-1" @click="activeList($api.projectLimitDelete,'delete','删除',{id:item.id})">mdi-delete-outline</v-icon>
                </div>
              </template>
            </v-data-table>
            <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
              <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getList" @changePageSize="changePageSize" />
            </div>
          </div>
        </template>
      </template>
      <div v-else-if="tab === '1'" class=" flex-1">
        <div>
          <div class=" flex-y-center px-4 pb-2">
            <span class=" flex-0 flex-shrink-0">归档文件上传格式限制：</span>
            <form-item
              v-model="file.data[`ARCHIVE_FILE_LIMIT_TYPE`]"
              class=" flex-0 flex-shrink-0"
              :options="[{text:'仅支持PDF',value:'1'},{text:'不限格式',value:'0'}]"
              style="width: 250px"
              dense
              type="radio"
              @input="$forceUpdate()"
            />
          </div>
          <div class=" flex-y-center px-4 pb-2 mb-4">
            <span class=" flex-0 flex-shrink-0">签名文件上传格式限制：</span>
            <form-item
              v-model="file.data[`SIGNATURE_FILE_LIMIT_TYPE`]"
              class=" flex-0 flex-shrink-0"
              :options="[{text:'仅支持PDF',value:'1'},{text:'不限格式',value:'0'}]"
              style="width: 250px"
              dense
              type="radio"
              @input="$forceUpdate()"
            />
          </div>
          <div class=" flex-y-center px-4 pb-12 mb-12" style="margin-left:160px;">
            <v-btn small color="primary" depressed min-width="120" class="mr-2" height="30" style="padding:0 8px" @click="saveFileLimit">保存</v-btn>
          </div>
        </div>
      </div>
      <div v-else-if="tab === '2'" class=" flex-1">
        <div class=" flex-y-center mt-2 px-4 pb-8">
          <span class=" flex-0 flex-shrink-0">回收站文件保留天数：</span>
          <form-item
            v-model="project.data.limitDay"
            class=" flex-0 flex-shrink-0"
            style="width: 150px"
            dense
            type="text"
            @input="$forceUpdate()"
          />
        </div>
        <div class=" flex-y-center px-4 pb-12 mb-12" style="margin-left:140px;">
          <v-btn small color="primary" depressed min-width="120" class="mr-2" height="30" style="padding:0 8px" @click="saveRecycleFileLimit">保存</v-btn>
        </div>
      </div>
      <!-- <div v-else-if="tab === '3'" class=" flex-1">
      </div> -->
      <div v-else-if="tab === '4'" class=" flex-1">
        <projectLimitProgress ref="projectLimitProgress" />
      </div>
      <div v-else-if="tab === '5'" class=" flex-1">
        <audit ref="audit" />
      </div>
      <div v-else-if="tab === '6'" class=" flex-1">
        <Scheme ref="Scheme" />
      </div>
      <div v-else-if="tab === '7'" class=" flex-1">
        <WorkHour ref="WorkHour" />
      </div>
      <div v-else-if="tab === '8'" class=" flex-1">
        <Sae ref="Sae" />
      </div>
      <div v-else-if="tab === '9'" class=" flex-1">
        <FinancialSycn ref="FinancialSycn" />
      </div>
    </div>
    <!-- 新增 编辑 项目数限制 -->
    <v-dialog v-model="dialog.show" width="700">
      <v-card>
        <form @submit.prevent="submit">
          <v-card-title class="font-weight-bold">{{ dialog.data.id?'编辑':'新增' }}</v-card-title>
          <v-divider />
          <v-card-text class=" py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12 mr-12">
              <div v-if="dialog.show" class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>选择用户：</div>
                <form-item
                  v-model="dialog.data.userId"
                  class=" flex-1 flex-shrink-0"
                  :options="userOption"
                  placeholder="选择用户"
                  required
                  dense
                  type="select"
                  :item-text="`userName`"
                  :item-key="`userId`"
                  allow-close
                  @input="$forceUpdate()"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">允许添加项目数：</div>
                <div class=" flex-1 flex-column">
                  <form-item
                    v-model="dialog.data.projectLimit"
                    class="mr-0"
                    placeholder="允许添加项目数"
                    type="text"
                    allow-close
                    @changeInput="limitTest"
                    @cleanVal="(e)=>{limitTest(e.val)}"
                  />
                  <span v-if="errorLimit" class="pt-1 error--text">请输入正整数！</span>
                </div>
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">有效期：</div>
                <div class=" flex-y-center">
                  <form-item
                    v-model="dialog.data.startTime"
                    class=" flex-1 flex-shrink-0"
                    placeholder="有效期"
                    dense
                    allow-close
                    type="allTime"
                  />
                  <span class=" px-2">~</span>
                  <form-item
                    v-model="dialog.data.endTime"
                    class=" flex-1 flex-shrink-0"
                    placeholder="有效期"
                    dense
                    allow-close
                    type="allTime"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn width="80" height="30" class depressed @click="dialog.show = false,dialog.data={}">取消</v-btn>
                <v-btn
                  :disabled="errorLimit"
                  width="80"
                  :loading="btnLoading"
                  class="ml-3"
                  height="30"
                  color="primary"
                  depressed
                  type="submit"
                >确定</v-btn>

              </div>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <!-- 新增 编辑 项目数限制 end -->
    <!-- 项目列表 start -->
    <v-dialog v-model="projectDialog.show" width="800">
      <v-card class=" flex-column flex-justify-between overflow-hidden" style="height:660px;">
        <v-card-title class=" flex-0 flex-shrink-0 font-weight-bold">已创建项目（{{ projectDialog.realName }}）</v-card-title>
        <v-divider />
        <v-card-text class=" flex-1 px-0 pb-5 overflow-auto">
          <div class="flex-1 flex-column overflow-auto ">
            <v-data-table
              :headers="projectDialog.headers"
              :items="projectDialog.list"
              :items-per-page="projectDialog.search.pageSize"
              class="pb-5 max-width header-grey "
              hide-default-footer
              item-key="id"
              no-data-text="暂无查询数据"
              disable-sort
            >
              <template v-slot:item.rowIndex="{index}">
                {{ (index+1)+(projectDialog.search.pageSize*(projectDialog.search.pageNo-1)) }}
              </template>
            </v-data-table>
            <div class=" flex-justify-end">
              <pagination
                v-model="projectDialog.search.pageNo"
                :size="projectDialog.search.pageSize"
                :total="projectDialog.total"
                @input="showProject"
                @changePageSize="(e)=>{projectDialog.search.pageSize=e,projectDialog.search.pageNo=1,$forceUpdate(),showProject()}"
              />
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class=" flex-0 flex-shrink-0">
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="80" height="30" class depressed @click="projectDialog.show = false">关闭</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 项目列表 end -->
  </v-layout>
</template>

<script>
import projectLimitProgress from './model/projectLimit/progress'
import audit from './model/projectLimit/audit'
import WorkHour from './model/projectLimit/WorkHour'
import Scheme from './model/projectLimit/scheme'
import Sae from './model/projectLimit/sae'
import FinancialSycn from './model/projectLimit/financialSycn'
export default {
  components: { projectLimitProgress, audit, WorkHour, Scheme, Sae, FinancialSycn },
  data() {
    return {
      tab: '0',
      project: {
        isLimit: 0,
        data: {
          isLimit: 0
        },
        limitDay: null
      },
      // file.data.isLimit
      file: {
        data: {}
      },
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      listLoading: false,
      btnLoading: false,
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '用户账号', value: 'userName' },
        { text: '用户姓名', value: 'realName' },
        { text: '允许添加项目数', value: 'projectLimit' },
        { text: '已创建项目数', value: 'projectUsed' },
        { text: '有效期', value: 'startTime' },
        { text: '最后更新时间', value: 'updateTime' },
        { text: '状态', value: 'statusFlag' },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: [],
      selected: [],
      dialog: {
        show: false,
        type: '',
        data: {
          end: '',
          start: ''
        }
      },
      projectDialog: {
        show: false,
        search: {
          pageSize: 10,
          pageNo: 1
        },
        total: 0,
        listLoading: false,
        headers: [
          { text: '序号', value: 'rowIndex', width: '50', sortable: false },
          { text: '项目编号', value: 'projectNumber' },
          { text: '方案编号', value: 'projectProtocolNumber' },
          { text: '项目名称', value: 'projectName' },
          { text: '项目负责人', value: 'projectManagerName' }
        ],
        list: []
      },
      errorLimit: false,
      userOption: [] // 用户下拉
    }
  },
  watch: {
  },
  mounted() {
    this.getProjectLimitStatus()
    // this.initList()
    this.getUserOption()
    this.getUploadLimit()
    this.getRecycleFileLimit()
  },
  methods: {
    initList() {
      this.search.pageNo = 1
      this.getList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getList()
    },
    //  获取用户列表
    getList() {
      this.total = 0
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageNo = this.search.pageNo
      search.pageSize = this.search.pageSize
      this.$http.get(this.$api.projectLimitLimitList, { data: { ...search }}).then(res => {
        this.listLoading = false
        this.list = res.result && res.result.records || []
        this.total = res.result && res.result.total || 0
      }).catch(() => {
        this.listLoading = false
      })
    },
    //  获取用户下拉列表
    getUserOption() {
      this.$http.get(this.$api.projectLimitUserOption, { data: {}}).then(res => {
        this.userOption = res.result || []
      }).catch(() => {
        this.userOption = []
      })
    },
    // 获取 回收站保存天数
    getRecycleFileLimit() {
      this.$http.get(this.$api.settingGetRecycleFileLimit, { data: {}}).then(res => {
        const _result = res.result && res.result || null
        this.$set(this.project, 'limitDay', _result)
        this.$set(this.project.data, 'limitDay', _result)
      }).catch(() => {
      })
    },
    // 获取 前台文件上传限制
    getUploadLimit() {
      this.$http.get(this.$api.projectLimitGetUploadLimit, { data: {}}).then(res => {
        const _result = res.result || []
        _result.forEach(item => {
          this.$set(this.file.data, item.key, item.value)
        })
      }).catch(() => {
      })
    },
    // 获取 前台用户添加项目限制
    getProjectLimitStatus() {
      this.$http.get(this.$api.projectLimitGetLimitStatus, { data: {}}).then(res => {
        this.$set(this.project, 'isLimit', (res.result && res.result.limitStatus) || 0)
        this.$set(this.project.data, 'isLimit', (res.result && res.result.limitStatus) || 0)
        if ((res.result && res.result.limitStatus) === 1) this.initList()
      }).catch(() => {
      })
    },
    // 确认设置 前台用户添加项目限制
    saveProjectStatus() {
      this.$confirm({ text: '是否确认保存？' }).then((vm) => {
        vm.loading = true
        this.$http.put(this.$api.projectLimitSaveLimitStatus, { data: { limitStatus: this.project.data.isLimit }, type: 'application/x-www-form-urlencoded' }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          this.$set(this.project, 'isLimit', this.project.data.isLimit)
          if (this.project.data.isLimit === 1) this.initList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      })
    },
    // 确认保存回收站保存天数
    saveRecycleFileLimit() {
      this.$confirm({ text: '是否确认保存？' }).then((vm) => {
        vm.loading = true
        this.$http.post(this.$api.settingSaveRecycleFileLimit, { data: { limitDay: this.project.data.limitDay }, type: 'application/x-www-form-urlencoded' }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          this.$set(this.project, 'limitDay', this.project.data.limitDay)
        }).catch(() => {
          this.$set(this.project.data, 'limitDay', this.project.limitDay)
          vm.show = false
          vm.loading = false
        })
      })
    },
    // 编辑角色 弹窗
    showEdit(item) {
      this.errorLimit = false
      this.dialog.show = true
      this.dialog.data = { ...item }
    },
    // 弹窗显示已创建项目
    showProject() {
      this.projectDialog.show = true
      this.projectDialog.listLoading = true
      const param = new URLSearchParams()
      param.append('id', this.projectDialog.id)
      param.append('createBy', this.projectDialog.createBy)
      param.append('pageNo', this.projectDialog.search.pageNo)
      param.append('pageSize', this.projectDialog.search.pageSize)
      this.$http.get(this.$api.projectLimitProjectList, { data: param }).then(res => {
        this.projectDialog.listLoading = false
        this.projectDialog.list = (res.result && res.result.records) || []
        this.projectDialog.total = (res.result && res.result.total) || 0
      }).catch(() => {
        this.projectDialog.total = 0
        this.projectDialog.list = []
        this.projectDialog.listLoading = false
      })
    },
    // 校验
    limitTest(e) {
      this.errorLimit = false
      if (e || (e === 0)) {
        const _c = /^[1-9]\d*$/
        if (!_c.test(e)) {
          this.errorLimit = true
        } else {
          this.errorLimit = false
        }
      }
    },
    // 操作其他按钮 确认禁用、恢复、删除
    activeList(api, method, title, par) {
      this.$confirm({ text: '是否确认' + title + '这条记录？' }).then((vm) => {
        const param = new URLSearchParams()
        for (const i in par) {
          param.append(i, par[i] || '')
        }
        vm.loading = true
        this.$http[method](api, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          if (method === 'delete') {
            this.initList()
          } else {
            this.getList()
          }
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 添加用户 弹出
    addShow() {
      this.errorLimit = false
      this.dialog.data = {}
      this.dialog.show = true
    },
    // 确认 添加 编辑用户
    submit() {
      this.limitTest(this.dialog.data.projectLimit)
      if (this.errorLimit) return
      let url = this.$api.projectLimitAdd
      let method = 'post'
      if (this.dialog.data.id) {
        url = this.$api.projectLimitEdit
        method = 'put'
      }
      this.btnLoading = true
      const _p = { ...this.dialog.data }
      _p.projectLimit = parseInt(this.dialog.data.projectLimit)
      this.$http[method](url, { data: { ..._p }}).then(res => {
        this.$message.success(res.message)
        this.dialog.show = false
        this.btnLoading = false
        this.dialog.data = {}
        this.getList()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    // 保存文件上传限制
    saveFileLimit() {
      this.$confirm({ text: '是否确认保存？' }).then((vm) => {
        vm.loading = true
        const _value = {}
        for (const i in this.file.data) {
          _value[i] = this.file.data[i]
        }
        this.$http.post(this.$api.projectLimitSaveCommonInfo, { data: { ..._value }}).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
        }).catch(() => {
          this.getUploadLimit()
          vm.show = false
          vm.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:160px;
    color: #777;
}
// ::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
//     border-bottom: thin solid rgb(156, 156, 156, .12) !important;
// }

</style>
