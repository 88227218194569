<template>
  <div>
    <v-row dense>
      <v-col sm="2" xl="2" md="2" lg="2" class=" flex-justify-end flex-center">
        财务管理文件同步：
      </v-col>
      <v-col sm="8" xl="8" md="8" lg="8">
        <form-item
          v-model="syncFlag"
          class=" flex-0 flex-shrink-0"
          :options="[{text:'是',value:1},{text:'否',value:0}]"
          style="width: 250px"
          dense
          type="radio"
          @input="onSyncFlagChange"
        />
      </v-col>
    </v-row>
    <v-row v-if="syncFlag" dense>
      <v-col sm="2" xl="2" md="2" lg="2" class="flex-justify-end flex-center">
        文件夹权限：
      </v-col>
      <v-col sm="8" xl="8" md="8" lg="8">
        <form-item
          v-model="folderPermissionsId"
          class="lex-0 flex-shrink-0"
          :options="folderPermissions"
          dense
          type="select"
          style="width: 320px"
          @input="onFolderPermissionChange"
        />
      </v-col>

    </v-row>
    <v-row v-if="syncFlag">
      <v-col sm="2" xl="2" md="2" lg="2" />
      <v-col sm="8" xl="8" md="8" lg="8">
        <v-data-table
          :headers="headers"
          :items="list.slice((search.pageSize*(search.pageNo-1)),(search.pageSize*search.pageNo))"
          :items-per-page="search.pageSize"
          class="pb-5 max-width header-grey"
          hide-default-footer
          item-key="id"
          no-data-text="暂无查询数据"
          :loading="listLoading"
        >
          <template
            v-slot:item.rowIndex="{index}"
          >{{ (index+1)+(search.pageSize*(search.pageNo-1)) }}</template>
          <template v-if="!listLoading" v-slot:item.hidden="{item}">
            <v-radio-group
              v-model="permissionsObj[item.id].pem"
              dense
              hide-details
              class="mt-0"
            >
              <v-radio :value="1" />
            </v-radio-group>
          </template>
          <template v-if="!listLoading" v-slot:item.readonly="{item}">
            <v-radio-group
              v-model="permissionsObj[item.id].pem"
              dense
              hide-details
              class="mt-0"
            >
              <v-radio :value="2" />
            </v-radio-group>
          </template>
          <template v-if="!listLoading" v-slot:item.writableUser="{item}">
            <v-radio-group
              v-model="permissionsObj[item.id].pem"
              dense
              hide-details
              class="mt-0"
            >
              <v-radio :value="4" />
            </v-radio-group>
          </template>
          <template v-if="!listLoading" v-slot:item.writable="{item}">
            <v-radio-group
              v-model="permissionsObj[item.id].pem"
              dense
              hide-details
              class="mt-0"
            >
              <v-radio :value="3" />
            </v-radio-group>
          </template>
        </v-data-table>
        <div class="mt-2 pr-2 mb-5 max-width flex-justify-end">
          <pagination
            v-model="search.pageNo"
            :size="search.pageSize"
            :total="total"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="2" xl="2" md="2" lg="2" />
      <v-col sm="8" xl="8" md="8" lg="8">
        <v-btn small color="primary" depressed min-width="120" class="mr-2" height="30" style="padding:0 8px" @click="saveProgressLimit">保存</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  data() {
    return {
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      permissionsObj: {},
      initInfo: {},
      info: {},
      syncFlag: 1,
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '项目角色', value: 'roleName' },
        { text: '隐藏', value: 'hidden' },
        { text: '只读', value: 'readonly' },
        { text: '可写（本人）', value: 'writableUser' },
        { text: '可写（所有）', value: 'writable' }
      ],
      list: [],
      listLoading: false,
      folderPermissionsId: '0:财务管理（自动同步）/中心财务-合同',
      folderPermissions: [
        {
          text: '财务管理（自动同步）/中心财务/合同',
          value: '0:财务管理（自动同步）/中心财务-合同'
        }, {
          text: '财务管理（自动同步）/中心财务/付款记录',
          value: '0:财务管理（自动同步）/中心财务-付款记录'
        }, {
          text: '财务管理（自动同步）/中心财务/其他费用',
          value: '0:财务管理（自动同步）/中心财务-其他费用'
        }, {
          text: '财务管理（自动同步）/供应商财务/合同',
          value: '1:财务管理（自动同步）/供应商财务-合同'
        }, {
          text: '财务管理（自动同步）/供应商财务/付款记录',
          value: '1:财务管理（自动同步）/供应商财务-付款记录'
        }, {
          text: '财务管理（自动同步）/供应商财务/其他费用',
          value: '1:财务管理（自动同步）/供应商财务-其他费用'
        }
      ]

    }
  },
  mounted() {
    this.getRoleList()
  },
  methods: {
    // 获取
    getRoleList() {
      this.total = 0
      const search = { roleCategory: '1' }
      search.pageNo = 1
      search.pageSize = 999
      const param = new URLSearchParams()
      for (const i in search) {
        param.append(i, search[i] || '')
      }
      this.$http
        .get(this.$api.roleList, { data: { ...search }})
        .then(res => {
          this.list = (res.result && res.result.records) || []
          this.total = (res.result && res.result.total) || 0
          this.onFolderPermissionChange(this.folderPermissionsId)
        })
    },
    // 确认保存
    saveProgressLimit() {
      const permissions = []
      for (const key in this.permissionsObj) {
        permissions.push(this.permissionsObj[key])
      }
      const folderPermissions = this.folderPermissionsId
      const json = {
        folderCategory: folderPermissions && folderPermissions.split(':')[0],
        folderName: folderPermissions && folderPermissions.split(':')[1].split('-')[1] + '',
        permissions: permissions,
        syncFlag: this.syncFlag
      }
      this.btnLoading = true
      this.$http.post(this.$api.saveFolderPermission, { data: json }).then(res => {
        this.$message.success(res.result || res.message)
        this.btnLoading = false
      }).catch(() => {
        this.btnLoading = false
      })
    },
    onFolderPermissionChange(e) {
      this.folderPermissionsId = e
      const parmas = e.split(':')
      const json = {
        folderCategory: parmas[0] + '',
        folderName: parmas[1].split('-')[1] + ''
      }
      this.listLoading = true
      this.$http.get(this.$api.getFolderPermission, { data: json }).then(res => {
        this.listLoading = false
        this.appliedToSubfolders = res.result.appliedToSubfolders
        const permissions = (res.result && res.result.permissions) || []
        const _obj = {}
        permissions.forEach(item => {
          _obj[item.roleId] = { ...item }
        })
        const permissionsObj = {}
        this.list.forEach(item => {
          permissionsObj[item.id] = { pem: 1, roleId: item.id }
          if (_obj[item.id]) {
            permissionsObj[item.id] = { ..._obj[item.id] }
          }
        })
        this.permissionsObj = permissionsObj
      })
    },
    onSyncFlagChange(e) {
      if (e) {
        this.folderPermissionsId = '0:财务管理（自动同步）/中心财务-合同'
        this.onFolderPermissionChange(this.folderPermissionsId)
      } else {
        this.folderPermissionsId = ''
        this.permissionsObj = {}
      }
    }

  }
}
</script>

<style>

</style>
